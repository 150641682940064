const PROVIDED_BY = [
    {
        "label": "Vendor",
        "value": "vendor"
    },
    {
        "label": "Doctor",
        "value": "doctor"
    },
    {
        "label": "Hospital",
        "value": "hospital"
    },
    {
        "label": "Pristyn",
        "value": "pristyn"
    },
]

const AVAILABILITY = [
    {
        "label": "Order Placed",
        "value": "Order Placed"
    },
    {
        "label": "In Stock",
        "value": "In Stock, will be made available "
    },
    {
        "label": "Arrived at Hospital",
        "value": "Arrived at Hospital"
    },
]

const UTILIZATION_STATUS= [
    {
        "label": "Pending",
        "value": "pending"
    },
    {
        "label": "Used",
        "value": "used"
    },
    {
        "label": "Not Used",
        "value": "notUsed"
    },
]

const EMR_TYPE_LIST = [
    { category: 'Aesthetics', type: 'web' },
    { category: 'Ayurveda', type: 'web' },
    { category: 'BPH', type: 'native' },
    { category: 'Breast', type: 'web' },
    { category: 'Cosmetic Gynaecology', type: 'web' },
    { category: 'Covid-19', type: 'web' },
    { category: 'Dental', type: 'web' },
    { category: 'Dermatology', type: 'web' },
    { category: 'Diagnostic', type: 'web' },
    { category: 'ENT', type: 'native' },
    { category: 'General', type: 'web' },
    { category: 'General Gynaecology', type: 'web' },
    { category: 'General Physician', type: 'web' },
    { category: 'Gynaecology', type: 'web' },
    { category: 'Gynaecology MTP', type: 'web' },
    { category: 'HairTransplant', type: 'web' },
    { category: 'IVF', type: 'web' },
    { category: 'Kidney Stone', type: 'native' },
    { category: 'Laparoscopy', type: 'web' },
    { category: 'Lasik', type: 'native' },
    { category: 'Online Consulting', type: 'web' },
    { category: 'Ophthalmology', type: 'native' },
    { category: 'Orthopaedics', type: 'native' },
    { category: 'Pilonidal Sinus', type: 'web' },
    { category: 'Proctology', type: 'web' },
    { category: 'Surgical Gynaecology', type: 'web' },
    { category: 'Urology', type: 'web' },
    { category: 'Urology 2', type: 'web' },
    { category: 'Vascular', type: 'web' },
    { category: 'Weight Loss', type: 'native' },
]

export {PROVIDED_BY, AVAILABILITY, UTILIZATION_STATUS, EMR_TYPE_LIST}