import { Cancel, Close, InfoOutlined, Print, VideoFile } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArticleIcon from "@mui/icons-material/Article";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import SendIcon from "@mui/icons-material/Send";
import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, ImageListItem, InputLabel, MenuItem, Popover, Select, Snackbar, Tooltip, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uniqid from "uniqid";
import allDocsIcon from '../../../assets/allDocsIcon.svg';
import privateModeOn from '../../../assets/private_mode_on.svg';
import privateModeOff from '../../../assets/private_mode_off.svg';
import privateAttachment from '../../../assets/private-material-attachment.svg';
import PathLargeScreen from '../../../assets/PathLargeScreen.svg'
import PathMobileScreen from '../../../assets/PathMobileScreen.svg'
import infoIcon from '../../../assets/infoIcon.svg';
import rightArrow from '../../../assets/rightArrow.png';
import { constants } from "../../../mixPanel/constants";
import { Mixpanel } from "../../../mixPanel/MixPanel";
import { chatBoxActions, configActions, homeStateActions, patientActions } from "../../../_actions";
import { DailogHWA } from "../../../_components";
import { chatBoxConstants, hospitalConstants } from "../../../_constants";
import { getInitialState } from "../../../_helpers/getInitialState";
import { changeScreen, Debounce, detectBrowser, EXTERNAL_HOSPITAL_ROLES, getInitials, GetRandomColor, getTypeOfUrl, mainCacheConstants, useReduxCacheAndDispatch } from "../../../_helpers";
import useNetwork from "../../../_helpers/useNetwork";
import useWindowSize from "../../../_helpers/useWindowSize";
import CustomInfiniteScroller from "../CustomInfiniteScroll/CustomInfiniteScroll";
import { chatBoxService, getPatientMessages } from "../../../_services";
import DocumentPopover from "../UploadDocument/DocumentPopover";
import PreviewDocument from "../UploadDocument/PreviewDocuments";
import crossIconOrange from './../../../assets/crossIconOrange.svg';
import deleteIcon from './../../../assets/Delete.svg';
import ChatMessage from "./ChatMessage/ChatMessage";
import Feedback from './components/Feedback';
import MessageText from "./components/MessageBody";
import MessageInput from "./components/MessageInput";
import MobileMessageInput from "./components/MobileMessageInput/MobileMessageInput";
import "./styles.css";
import ButtonHWA from "../../../_components/ButtonHWA/ButtonHWA";
import { WebSocketContext } from "../../../contexts/WebSocket";


const getAvatarColor = new GetRandomColor();

const inputDebounce = new Debounce();

function ChatBox({ }) {
  const documentPopoverRef = useRef();
  const externalRoles = EXTERNAL_HOSPITAL_ROLES;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const childRef = useRef();
  const networkState = useNetwork();
  const { online } = networkState;
  const [input, setInput] = useState("");
  const [showPatientInfo, setShowPatientInfo] = useState(false);
  const [selectedMsgId, setSelectedMsgId] = useState("");
  const [showDeleteMsgPopUp, setShowDeleteMessagePopUp] = useState(false);
  const [showUploadedFile, setShowUploadedFile] = useState("");
  const [sendingDoc, setSendingDoc] = useState(false);
  const [fileType, setFileType] = useState("");
  const [imgSrc, setImgSrc] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [pdfSrc, setPdfSrc] = useState(null);
  const [docSrc, setDocSrc] = useState(null);
  const [fileName, setFileName] = useState("");
  const [selectedFileProps, setSelectedFileProps] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [showReplyMessage, setShowReplyMessage] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [selectedDocIdx, setSelectedDocIdx] = useState(0);
  const [docType, setDocType] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const chatTemplates = useSelector((state) => state.chatBox.chatTemplates);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [showMessageTemplatePopUp, setShowMessageTemplatePopUp] = useState(false);
  // const [selectedDownloadImage, setSelectedDownloadImage] = useState("");
  const [mediaUrlsList, setMediaUrlsList] = useState([]);
  const [showChatMediaDialog, setShowChatMediaDialog] = useState(false);
  const chatInputRef = useRef(null);
  const [insFile, setInsFile] = useState("");
  const [sortedMessages, setSortedMessages] = useState([]);
  const [uploadDocumentTags, setUploadDocumentTags] = useState([]);
  const [isShowPreviewDocument, setIsShowPreviewDocument] = useState(false)
  const [unreadCount,setUnreadCount] = useState(0)
  const [isPrivateChat, setPrivateChat ] = useState(true)
  const [pxRoleChatAllowed,setPxRole] = useState(true)
  const [isRecording, setIsRecording] = useState(false);
  const priavteChatIconRef = useRef(null)
  const highlightPrivateIcon = useRef(null)
  const highlightPrivateContent = useRef(null)   
  const { isMobile } = useWindowSize()
  // const [hasMore, setHasMore] = useState(true);
  const settings = {
    dots: false,
    speed: 500,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  // const steps = [{selector:'#privateChatIcon',content:}]
  const [options, setOptions] = useState([
    "Reply",
    "Delete",
  ]);
  const dispatch = useDispatch();
  const cacheAndDispatch = useReduxCacheAndDispatch();
  const authentication = useSelector((state) => state.authentication);
  const showGeneralChatMessages =
    useSelector((state) => state.homeState.showGeneralChatMessages) ?? false;
  const showChatBox = useSelector((state) => state.homeState.showChatBox);
  const isInternalUser = useSelector((state) => state.authentication?.user.isInternalUser);
  const currentHospitalData = useSelector(
    (state) => state.hospitals.currentHospitalData
  );
  const friendly_name =
    useSelector((state) => state.hospitals.currentHospitalData?.friendly_name) ??
    "";
  const hospitals = useSelector((state) => state.hospitals);
  const config = useSelector((state) => state.config);
  const showSpinningLoader = useSelector(
    (state) => state.config.showSpinningLoader
  );
  const chatBox = useSelector((state) => state.chatBox);
  const patientMessages = useSelector((state) => state.chatBox.patientMessages) ?? [];
  const privateChatMessages = useSelector((state)=> state.chatBox.privateChatMessages) ?? [];
  const generalChatMessages = useSelector((state) => state.chatBox.generalChatMessages) ?? [];
  const patientChatMessagesActive = useSelector(
    (state) => state.chatBox.patientChatMessagesActive
  );
  const GeneralChatMessagesActive = useSelector(
    (state) => state.chatBox.GeneralChatMessagesActive
  );
  const selectedMessage = useSelector((state) => state.chatBox.selectedMessage);
  const hasMoreMessages = useSelector((state) => state.chatBox.hasMoreMessages);
  const privateChatMessagesActive = useSelector((state)=> state.chatBox.privateChatMessagesActive)
  const patients = useSelector((state) => state.patients);
  const patientInfoActive = useSelector(
    (state) => state.patients.patientInfoActive
  );
  const endOfChatArr = useSelector((state) => state.patients.endOfChatArr);
  const result =
    useSelector((state) => state.patients.patientInfoAPIData.result) ?? [];
  const FirstName = useSelector(
    (state) => state.patients.patientInfo.allInfo.FirstName
  );
  const patientInfo = useSelector((state) => state.patients.patientInfo);
  const blockChatBox = useSelector(state => state.chatBox.blockChatBoxScreen)
  const isDrawer = useSelector((state) => state.hospitals.isDrawer)
  const socketConnectionStatus = useSelector((state)=>state.socketConnectivity.socketConfig.socketConnect)
  const showPrivateChatPopup = useSelector((state) => state.documentsPopup.showPrivateChatPopup)

  const { generalGroupEditors } = config?.versionConfigData?.result[0] || {};
  const docTaggers = config?.versionConfigData?.result[0].docTaggers || []
  const { role, otherRoles } = authentication.user.roles;

  //this is for new preview dialog
  const [previewDocumentTitle, setPreviewDocumentTitle] = useState("");
  const [previewDocumentUrls, setPreviewDocumentUrls] = useState([])
  const [previewDocumentFiles, setPreviewDocumentFiles] = useState([])
  const [previewDocumentDocType, setPreviewDocumentDocType] = useState("")



  const { data } = config.documentList;
  const [documentList, setDocumentList] = useState(data);
  const [sendToInsuranceList, setSendToInsuranceList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showOptions, setShowOptions] = useState(null);
  const [showInsurancePopup, setShowInsurancePopup] = useState(false);
  const [messageInputText, setMessageInputText] = useState("");
  const [mentionData, setMentionData] = useState([]);
  const [suggestDocChangeReply, setSuggestDocChangeReply] = useState(false);
  //to get opthal form body
  const opthalFormData = useSelector(state => state?.config?.opthalFormData)
  const { sendSocketMessage } = useContext(WebSocketContext);
  useEffect(() => {
    if (!opthalFormData) {
      dispatch(configActions.getCommonOpthalData({}))
    }
  }, [])
  
  useEffect(()=>{

    if((role === 'PX' || otherRoles?.includes('PX')) && !privateChatMessagesActive){
      setPxRole(false)
    }
    else{
      setPxRole(true)
    }
  },[privateChatMessagesActive,otherRoles])

  useEffect(()=>{
    if(patientInfo.allInfo?.unreadMessages){
      setUnreadCount(patientInfo.allInfo.unreadMessages)
      return
    }
    setUnreadCount(0)
  },[patientInfo.allInfo.unreadMessages])

  useEffect(() => {
    setDocumentList(data)
  }, [data])
  const handleClick3 = () => {

    setOpen3(true);

  };
  const handleClick4 = () => {
    setOpen4(true);
  };
  /* to scroll to the last message in the chat window ----------- */
  const scrollToView = () => {
    // [...document.querySelectorAll(".chat__message")]
    //   ?.slice(-1)[0]
    //   ?.scrollIntoView();
  };

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    // document.getElementById("chat_body_main").classList.remove("smoothScroll");
    setFirstRender(true);
    dispatch(chatBoxActions.hasMoreMessages(true));
    const {
      allInfo: { disableChat },
    } = patients.patientInfo;
  }, [
    patientInfo,
    currentHospitalData,
    GeneralChatMessagesActive,
    patientChatMessagesActive,
    showGeneralChatMessages,
  ]);
  useEffect(()=>{
    let scrollContainer = document.getElementById("customScrollContainer")
    if (scrollContainer){
      setTimeout(() => {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }, 0);
    }
    },[patientInfo.appointmentId,patientInfo.patientId])
  const checkScroll = () => {
    var objDiv = document?.querySelector("customScrollContainer");
    if (objDiv) {
      objDiv.scrollTop = objDiv?.scrollHeight
    }
  }

  useEffect(() => {
    scrollToView();

    if(isInternalUser && socketConnectionStatus){
      let privatePopupPaylaod = {
        action: "privateChatEvent",
        payload:{
          privateChatFlag:false
        }
      }
        sendSocketMessage(privatePopupPaylaod)
    }

  }, []);

  useEffect(() => {
    if (!showPrivateChatPopup) { //uncomment this when pushing
    if (priavteChatIconRef.current && highlightPrivateIcon.current && highlightPrivateContent.current) {
      const lineSVG = document.querySelector(".dotted-line")
      const iconDimensions = priavteChatIconRef.current.getBoundingClientRect()
      const popUpDimensions = highlightPrivateContent.current.getBoundingClientRect()
      lineSVG.style.height = `${popUpDimensions['top'] - iconDimensions['bottom'] - 97}px` // 97 is calculated using box model
      lineSVG.style.width = `${(iconDimensions['left'] + iconDimensions['width']) - (popUpDimensions['width'] / 2 + popUpDimensions['left'])}px`
      lineSVG.style.top = `${-(popUpDimensions['top'] - iconDimensions['bottom'] - 97)}px` // 97 is calculated using box model
      lineSVG.style.left = `${popUpDimensions['width'] / 2}px`
      highlightPrivateIcon.current.style.left = `${iconDimensions['left'] - 8}px`
      highlightPrivateIcon.current.style.top = `${iconDimensions['top'] - 8}px`
    }
    }
  })

  useEffect(() => {
    scrollToView();
  }, [generalChatMessages?.length]);

  const {
    appointmentId,
    leadId,
    patientId,
    allInfo: { paymentMode, disableChat = false },
  } = patients.patientInfo;
  useEffect(() => {
    const {
      appointmentId,
      leadId,
      patientId,
      allInfo: { paymentMode },
    } = patients.patientInfo;
    const { data } = config.documentList;
    if (showGeneralChatMessages) {
      let something = data?.filter((item) => {
        return item["Key"] === "hospRegistrationCertificate";
      });
      setUploadDocumentTags(something);
    } else {
      let something = data?.filter((item) => {
        return !item.paymentMode || item.paymentMode.includes(paymentMode);
      });

      setUploadDocumentTags(something);
    }
  }, [
    patientId,
    generalChatMessages,
    patientMessages,
    GeneralChatMessagesActive,
    showGeneralChatMessages,
    config.documentList,
  ]);

  /* to scroll to the last message in the chat window ----------- */


  const handleUploadDocumentClick = ({ event, ...rest }) => {
    dispatch(homeStateActions.setUploadDocumentStatus({ status: false }))
    documentPopoverRef.current.handleClick({ event: event.target, ...rest })
  }
  const handleClick = (event) => {
    dispatch(homeStateActions.setUploadDocumentStatus({ status: false }))
    documentPopoverRef.current.handleClick({ event: event.target, item: patients.patientInfo })
    // setAnchorEl(event.currentTarget);
    // setUploadedDocs([]);
  };

  const handleDocumentsClick = () => {
    dispatch(
      homeStateActions.setState({
        showDocumentsPopup: true,
      })
    );
  };

  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event, id) => {
    var element = document.getElementById(id)
    var browserName = detectBrowser()
    if (browserName === 'safari') {

      setTimeout(() => {

        setShowOptions(element);
      }, 400);
    } else {
      setShowOptions(element);
    }

    // setTimeout(() => {
    //   console.log('getting here ',event.currentTarget)
    // }, 0);
  };
  const handleClose2 = () => {
    setShowOptions(null);
  };

  const handleClose3 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen3(false);
  };
  const handleClose4 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen4(false);
  };
  const handleChatInfoClick = () => {
    // setShowPatientInfo(true);
    const { hospital_id } = hospitals.currentHospitalData;
    const { appointmentId } = patients.patientInfo;
    if(appointmentId && hospital_id){
      navigate(`/central/${hospital_id}/${appointmentId}`)
    }
    else{
      navigate('/central')
    }
  };
  const generateLocalId = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const scrollChatToBottom = () => {
    // let objDiv = document.getElementsByClassName("scrollableDiv")[0];
    // console.log('scroll scroll this is obj div', objDiv)
    // if (objDiv) {
    //   console.log('scroll scroll this is scroll obj div top and height', objDiv.scrollTop, objDiv.scrollHeight)
    //   objDiv.scrollTop = objDiv?.scrollHeight;
    // }
  };

  const sortMessages = (messages) => {
    messages?.sort((current, next) => {
      return Number(Number(current["CreatedAsDate"] - next["CreatedAsDate"]));
    });

    return messages;
  };

  //sorted patientMessages ---------------------------------
  useEffect(() => {
    showGeneralChatMessages
      ? setSortedMessages(sortMessages(generalChatMessages))
      : privateChatMessagesActive 
      ? setSortedMessages(sortMessages(privateChatMessages))
      : setSortedMessages(sortMessages(patientMessages))

    let tempMessages = showGeneralChatMessages
      ? generalChatMessages
      : privateChatMessagesActive
      ? privateChatMessages
      : patientMessages;

    if (
      tempMessages.length == 0 ||
      tempMessages.length < chatBoxConstants.MESSAGES_LIMIT
    ) {
      dispatch(chatBoxActions.hasMoreMessages(false));
    }
  }, [
    generalChatMessages,
    patientMessages,
    privateChatMessages,
    privateChatMessagesActive,
    GeneralChatMessagesActive,
    showGeneralChatMessages,
    dispatch,
  ]);

  //sorted patientMessages ---------------------------------

  const messageActions = (idx, item, suggestDocChange = null) => {
    dispatch(chatBoxActions.setSelectedMessage(item));
    if (idx == 1) {
      setShowDeleteMessagePopUp(true);
      setShowOptions(false);
    } else if (idx == 0) {
      if (suggestDocChange) {
        setSuggestDocChangeReply(true);
      } else {
        setSuggestDocChangeReply(false);
      }
      setShowReplyMessageRef(true);
      setShowOptions(false);
    } else {
      setShowInsurancePopup(true);
      setShowOptions(false);
    }
  };

  const deleteMessage = () => {
    const {
      hasMedia,
      hospital_id,
      ChannelSid,
      Author,
      MessageSid,
      PatientId,
      LeadId,
      AppointmentId,
      MessageIndex,
      DateCreated,
      Attributes: { uploadLocalId }
    } = chatBox.selectedMessage;

    let params = {
      hasMedia: hasMedia,
      uploadLocalId: uploadLocalId,
      hospitalId: hospital_id,
      channelSid: ChannelSid,
      author: Author,
      messageSid: MessageSid,
      patientId: PatientId,
      leadId: LeadId,
      messageIndex: MessageIndex,
      appointmentId: AppointmentId,
    };

    dispatch(
      chatBoxActions.deleteChatMessage({
        uploadLocalId,
        showGeneralChatMessages,
        privateChatMessagesActive
      })
    );
    dispatch(chatBoxActions.deleteMessage(params));
    setShowDeleteMessagePopUp(false);
  };

  const removeDoc = (id) => {
    var temp2 = [];
    temp2 = mediaUrlsList;
    temp2 = temp2.filter((item, idx) => {
      if (idx !== id) {
        return item;
      }
    });
    setMediaUrlsList(temp2);
    var temp = [];
    temp = uploadedDocs;
    temp = temp.filter((item, idx) => {
      if (idx !== id) {
        return item;
      }
    });
    setUploadedDocs(temp);

    if (selectedDocIdx === id) {
      if (temp2.length > 0) {
        if (docType === "pdf") {
          setPdfSrc(temp2[0]);
        }
        if (docType === "image") {
          setImgSrc(temp2[0]);
        }
        if (docType === "video") {
          setVideoSrc(temp2[0]);
        }
        setSelectedDocIdx(0);
      } else {
        handleClose();
        setShowUploadedFile(false);
        setUploadedDocs([]);
      }
    }
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const uploadDocument = async () => {
    let file = document.getElementById(`upload${docType}`).files[0];
    let length = document.getElementById(`upload${docType}`).files.length;
    let docList = uploadedDocs;
    if (uploadedDocs.length + length > 6) {
      return alert("You can upload max 6 documents");
    }
    for (let i = 0; i < length; i++) {
      docList.push(document.getElementById(`upload${docType}`).files[i]);
    }

    setImgSrc(null);
    setVideoSrc(null);
    setPdfSrc(null);
    setDocSrc(null);
    setFileName("");
    setSelectedFile(file);
    setSelectedDocIdx(0);
    setUploadedDocs(docList);

    if (docList.length > 0) setShowUploadedFile(true);
    var arr = [];
    let data = [];
    if (docType === "doc" || docType === "excel" || docType === "csv") {
      for (let i = 0; i < docList.length; i++) {
        let tempObj = {};
        let file = docList[i];
        tempObj.fileData = await toBase64(file);
        tempObj.fileName = file.name;
        data.push(tempObj);
      }
      await chatBoxService.uploadToS3({ data: data }).then(async (res) => {
        data = [];
        let result = res.result;
        for (let i = 0; i < result.length; i++) {
          let obj = result[i];
          for (let key in obj) data.push(obj[key]);
        }

        await chatBoxService
          .downloadChatMessage({ urls: data, isDownload: false })
          .then((res) => {
            arr = res.result;
          });
        for (let i = 0; i < arr.length; i++) {
          arr[i] =
            "https://view.officeapps.live.com/op/embed.aspx?src=" +
            encodeURIComponent(arr[i]);
        }
      });
    } else {
      for (let i = 0; i < docList.length; i++) {
        let file = docList[i];
        var temp = URL.createObjectURL(file);
        arr.push(temp);
      }
    }

    setMediaUrlsList(arr);

    // this sets the preview url
    if (file) {
      if (file && file["type"].split("/")[0] === "image") {
        setImgSrc(URL.createObjectURL(file));
      } else if (docType === "video") {
        setVideoSrc(URL.createObjectURL(file));
      } else if (docType === "csv") {
        setDocSrc(arr[0]);
      } else if (docType === "doc" || docType === "excel") {
        setDocSrc(arr[0]);
      } else {
        setPdfSrc(URL.createObjectURL(file));
      }
    }
  };
  const sendMessage = () => {
    const { hospital_id, sid } = hospitals.currentHospitalData;
    const { user } = authentication;
    const { appointmentId, leadId, patientId } = patients.patientInfo;
    const { AppointmentStartTime, FirstName, category, age } =
      patients.patientInfo.allInfo;
    const { userId, name, SRNumber } = authentication.user;
    const { role } = authentication.user.roles;
    let localId = generateLocalId(30);
    //  const uploadLocalId=""

    // setLoader(true);
    let tempInput = messageInputText;
    setInput(tempInput);
    let temp = tempInput.replace(/\s/g, "");
    if (!temp || temp.length == 0) {
      return;
    }
    if (showReplyMessageRef.current == false) {
      var date = new Date();
      const addMessagePayload = {
        isSync: false,
        userid: userId,
        hospId: hospital_id,
        mobileNumber: localStorage.getItem("userMobile").toString(),
        messageBody: "omethign",
        Author: localStorage.getItem("userMobile"),
        AuthorName: name,
        MessageBody: tempInput,
        DateCreated: date.toISOString(),
        AuthorRole: role,
        ChannelSid: sid,
        Attributes: {
          isVisible: true,
          uploadLocalId: localId,
          hospital_id: hospital_id,
          userId: userId,
          authorName: name,
          authorRole: role,
          has_media: false,
          appointment_id: appointmentId,
          lead_id: leadId,
          patient_name: FirstName,
          patientId: patientId,
          age: age,
          appointmentTime: AppointmentStartTime?.toString(),
          category: category,
          mention_data: mentionData,
          chatType: privateChatMessagesActive? "private":""
        },
        messageType: "TEXT",
      };
      const reqPayload = {
        hospId: hospital_id,
        channelSid: sid,
        mobileNumber: localStorage.getItem("userMobile").toString(),
        messageBody: tempInput,
        attributes: {
          uploadLocalId: localId,
          hospital_id: hospital_id,
          userId: userId,
          authorName: name,
          authorRole: role,
          message_type: "TEXT",
          has_media: false,
          appointment_id: appointmentId,
          lead_id: leadId,
          patient_name: FirstName,
          patientId: patientId,
          age: age,
          appointmentTime: AppointmentStartTime?.toString(),
          category: category,
          mention_data: mentionData,
          chatType: privateChatMessagesActive? "private":""
        },
        messageType: "TEXT",
      };

      if (showGeneralChatMessages) {
        // modify addMessage payload for general chat
        let temp = addMessagePayload.Attributes;
        delete temp["appointment_id"];
        delete temp["lead_id"];
        delete temp["patient_name"];
        delete temp["patientId"];
        delete temp["age"];
        delete temp["appointmentTime"];
        delete temp["category"];
        addMessagePayload.Attributes = temp;

        // modify req payload for general chat

        temp = reqPayload.attributes;
        delete temp["appointment_id"];
        delete temp["lead_id"];
        delete temp["patient_name"];
        delete temp["patientId"];
        delete temp["age"];
        delete temp["appointmentTime"];
        delete temp["category"];
        addMessagePayload.Attributes = temp;
      }

      if(!privateChatMessagesActive){
        let temp = addMessagePayload.Attributes;
        delete temp["chatType"]

        temp = reqPayload.attributes;
        delete temp["chatType"]
      }
      Mixpanel.track(constants.SEND_MESSAGE, { type: "Text", source: "web" });
      dispatch(
        chatBoxActions.sendMessage(
          reqPayload,
          addMessagePayload,
          localId,
          online,
          showGeneralChatMessages,
          false,
          privateChatMessagesActive
        )
      );
      // document.getElementById('inputText').innerText = null;
      setMentionData([]);
      setMessageInputText('')
    } else {
      sendReply();
    }
    scrollChatToBottom();
    setShowReplyMessageRef(false);
    setInput("");
  };
  const sendReply = () => {
    Mixpanel.track(constants.SEND_MESSAGE, { type: "Reply", source: "web" });

    const { hospital_id, sid } = hospitals.currentHospitalData;
    const { user } = authentication;
    const { appointmentId, leadId, patientId } = patients.patientInfo;
    const { AppointmentStartTime, FirstName, category, age } =
      patients.patientInfo.allInfo;
    const { userId, name, SRNumber } = authentication.user;
    const { role } = authentication.user.roles;
    let localId = generateLocalId(30);
    let tempInput = messageInputText;
    setInput(tempInput);
    let temp = tempInput.replace(/\s/g, "");

    const addMessagePayload = {
      isSync: false,
      userid: userId,
      hospId: hospital_id,
      mobileNumber: localStorage.getItem("userMobile").toString(),
      MessageBody: tempInput,
      Author: localStorage.getItem("userMobile"),
      AuthorName: name,
      AuthorRole: role,
      MediaType: selectedMessage.MediaType,
      Attributes: {
        isVisible: true,
        reply_name: selectedMessage.AuthorName,
        reply_role: selectedMessage.AuthorRole,
        reply_user_type: selectedMessage.MediaType,
        message_reply_index: selectedMessage.MessageIndex,
        message_reply_text: selectedMessage.MessageBody,
        message_reply_media: selectedMessage.Media ? selectedMessage.Media : "",
        message_id: selectedMessage.MessageSid,
        uploadLocalId: localId,
        hospital_id: hospital_id,
        userId: userId,
        authorName: name,
        authorRole: role,
        message_type: selectedMessage.messageType,
        has_media: false,
        appointment_id: appointmentId,
        lead_id: leadId,
        patient_name: FirstName,
        patientId: patientId,
        age: age,
        appointmentTime: AppointmentStartTime?.toString(),
        category: category,
        mention_data: mentionData,
        chatType: privateChatMessagesActive? "private":""
      },
      mediaUrl: selectedMessage.mediaUrl,
      messageType: "TEXT",
    };
    const reqPayload = {
      userid: userId,
      hospId: hospital_id,
      channelSid: sid,
      mobileNumber: localStorage.getItem("userMobile").toString(),
      messageBody: tempInput,
      attributes: {
        reply_name: selectedMessage.AuthorName,
        reply_role: selectedMessage.AuthorRole,
        reply_user_type: selectedMessage.MediaType
          ? selectedMessage.MediaType
          : "",
        message_reply_index: selectedMessage.MessageIndex,
        message_reply_text: selectedMessage.MessageBody,
        message_id: selectedMessage.MessageSid,
        message_media_name: selectedMessage.MediaFileName,
        message_reply_media: selectedMessage.Media
          ? selectedMessage.Media.replace("jpg", "jpeg")
          : "",
        uploadLocalId: localId,
        hospital_id: hospital_id,
        userId: userId,
        authorName: name,
        authorRole: role,
        message_type: "TEXT",
        has_media: false,
        appointment_id: appointmentId,
        lead_id: leadId,
        patient_name: FirstName,
        patientId: patientId,
        age: age,
        appointmentTime: AppointmentStartTime?.toString(),
        category: category,
        mention_data: mentionData,
        chatType: privateChatMessagesActive? "private":""
      },
      messageType: "TEXT",
    };

    if (suggestDocChangeReply) {
      addMessagePayload["MessageBody"] =
        "Changes suggested from Pristyn's Insurance team<br>" + tempInput;
      reqPayload["messageBody"] =
        "Changes suggested from Pristyn's Insurance team:\n" + tempInput;
      addMessagePayload.Attributes["reply_suggest_changes"] =
        "Suggested Doc Changes";
      reqPayload.attributes["reply_suggest_changes"] = "Suggested Doc Changes";
    }
    if (showGeneralChatMessages) {
      // modify addMessage payload for general chat
      let temp = addMessagePayload.Attributes;
      delete temp["appointment_id"];
      delete temp["lead_id"];
      delete temp["patient_name"];
      delete temp["patientId"];
      delete temp["age"];
      delete temp["appointmentTime"];
      delete temp["category"];
      addMessagePayload.Attributes = temp;

      // modify req payload for general chat

      temp = reqPayload.attributes;
      delete temp["appointment_id"];
      delete temp["lead_id"];
      delete temp["patient_name"];
      delete temp["patientId"];
      delete temp["age"];
      delete temp["appointmentTime"];
      delete temp["category"];
      addMessagePayload.Attributes = temp;
    }

    if(!privateChatMessagesActive){
      let temp = addMessagePayload.Attributes;
      delete temp["chatType"]

      temp = reqPayload.attributes;
      delete temp["chatType"]
    }

    dispatch(
      chatBoxActions.sendReply(
        reqPayload,
        addMessagePayload,
        localId,
        online,
        showGeneralChatMessages,
        false,
        privateChatMessagesActive
      )
    );
    setInput("");
    setMentionData([]);
    setMessageInputText('')
  };
  const sendFile = () => {
    Mixpanel.track(constants.SEND_MESSAGE, { type: "Media", source: "web" });
    if (selectedFileProps.length == 0 && !showGeneralChatMessages) {
      setAlertMessage("Add Doc Type for the documents");
      handleClick3();
      return;
    }
    const { documentCheck } = config.versionConfigData.result[0];

    setShowUploadedFile(false);

    const { hospital_id, sid } = hospitals.currentHospitalData;
    const { user } = authentication;
    const { appointmentId, leadId, patientId } = patients.patientInfo;
    const { AppointmentStartTime, FirstName, category, age } =
      patients.patientInfo.allInfo;
    const { userId, name, SRNumber } = authentication.user;
    const { role } = authentication.user.roles;
    let localId = generateLocalId(30);
    //  const uploadLocalId=""

    // let tempInput = document.getElementById("inputText")?.innerText;
    // setInput(tempInput);
    // let temp = tempInput.replace(/\s/g, "");
    // setLoader(true);
    let currLeadId = leadId || undefined;
    let currApptId = appointmentId || undefined;
    const reqPayload = {
      leadId: currLeadId,
      appointmentId: currApptId,
      key: selectedFileProps[0]?.Key,
      destination: selectedFileProps[0]?.destination,
      subkey: selectedFileProps[0]?.SubKey,
      userid: userId,
      title: selectedFileProps[0]?.title,
      hospId: hospital_id,
      channelSid: sid,
      mobileNumber: localStorage.getItem("userMobile").toString(),
      files: uploadedDocs,
      messageBody: selectedFileProps[0] ? `${selectedFileProps[0]?.title} of ${FirstName}` : "File Uploaded",

      attributes: {
        message_media_name: selectedFile.name,
        has_media: true,
        message_type: "MEDIA",
        message_media_file_type: selectedFile.type,
        message_media_size: selectedFile.size,
        uploadLocalId: localId,
        hospital_id: hospital_id,
        userId: userId,
        authorName: name,
        authorRole: role,
        docTypeOfPatient: selectedFileProps[0]?.title,
        destination: selectedFileProps[0]?.destination,
        document_key: selectedFileProps[0]?.Key,
        appointment_id: appointmentId,
        lead_id: leadId,
        patient_name: FirstName,
        patientId: patientId,
        age: age,
        appointmentTime: AppointmentStartTime?.toString(),
        category: category,
        document_subkey: selectedFileProps[0]?.SubKey,
        chatType: privateChatMessagesActive? "private":""
      },
      messageType: "MEDIA",
    };
    const addMessagePayload = {
      isSync: false,
      leadId: leadId,
      appointmentId: appointmentId,
      key: selectedFileProps[0]?.Key,
      destination: selectedFileProps[0]?.destination,
      subkey: selectedFileProps[0]?.SubKey,
      userid: userId,
      title: selectedFileProps[0]?.title,
      hospId: hospital_id,
      mobileNumber: localStorage.getItem("userMobile").toString(),
      files: uploadedDocs,
      MessageBody: selectedFileProps[0] ? `${selectedFileProps[0]?.title} of ${FirstName}` : "File Uploaded",
      Author: localStorage.getItem("userMobile"),
      AuthorName: name,
      AuthorRole: role,
      mediaUrl: mediaUrlsList,
      Media: mediaUrlsList[0],
      hasMedia: true,
      loadingMedia: false,
      Attributes: {
        isVisible: true,
        message_media_name: selectedFile.name,
        has_media: true,
        message_type: "MEDIA",
        message_media_file_type: selectedFile.type,
        message_media_size: selectedFile.size,
        uploadLocalId: localId,
        hospital_id: hospital_id,
        userId: userId,
        multiUrl: mediaUrlsList,
        authorName: name,
        authorRole: role,
        docTypeOfPatient: selectedFileProps[0]?.title,
        destination: selectedFileProps[0]?.destination,
        document_key: selectedFileProps[0]?.Key,
        appointment_id: appointmentId,
        lead_id: leadId,
        patient_name: FirstName,
        patientId: patientId,
        age: age,
        appointmentTime: AppointmentStartTime?.toString(),
        category: category,
        document_subkey: selectedFileProps[0]?.SubKey,
        chatType: privateChatMessagesActive? "private":""
      },
      messageType: "MEDIA",
    };
    if (showGeneralChatMessages) {
      // modify addMessage payload for general chat
      let temp = addMessagePayload.Attributes;
      delete temp["appointment_id"];
      delete temp["lead_id"];
      delete temp["patient_name"];
      delete temp["patientId"];
      delete temp["age"];
      delete temp["appointmentTime"];
      delete temp["category"];
      addMessagePayload.Attributes = temp;

      // modify req payload for general chat

      temp = reqPayload.attributes;
      delete temp["appointment_id"];
      delete temp["lead_id"];
      delete temp["patient_name"];
      delete temp["patientId"];
      delete temp["age"];
      delete temp["appointmentTime"];
      delete temp["category"];
      reqPayload.attributes = temp;
    }

    if(!privateChatMessagesActive){
      let temp = addMessagePayload.Attributes;
      delete temp["chatType"]

      temp = reqPayload.attributes;
      delete temp["chatType"]
    }
    console.log('send file method',addMessagePayload,reqPayload)
    dispatch(
      chatBoxActions.sendFile(
        reqPayload,
        addMessagePayload,
        localId,
        online,
        showGeneralChatMessages,
        documentCheck,
        privateChatMessagesActive
      )
    );
    setInput("");
  };

  const updateSelectedFileProps = (item, idx) => {
    var tempArr = selectedFileProps;
    if (tempArr.length == 0) tempArr = new Array(uploadedDocs.length);
    tempArr[idx] = item;
    setSelectedFileProps(tempArr);
  };

  const sendChatTemplate = () => {
    setShowMessageTemplatePopUp(false);
    const { hospital_id, friendly_name, sid } = hospitals.currentHospitalData;
    const { user } = authentication;
    const { appointmentId, leadId, patientId } = patients.patientInfo;
    const { AppointmentStartTime, FirstName, category, age } =
      patients.patientInfo.allInfo;
    const { userId, name, SRNumber } = authentication.user;
    const { role } = authentication.user.roles;
    let localId = generateLocalId(30);
    //  const uploadLocalId=""

    // setLoader(true);

    var date = new Date();
    let messageBody = selectedTemplate["message"].replace(
      "{patientName}",
      FirstName
    );
    var currentDate = moment().calendar();
    messageBody = messageBody.replace("{time}", currentDate);

    const addMessagePayload = {
      isSync: false,
      userid: userId,
      hospId: hospital_id,
      mobileNumber: localStorage.getItem("userMobile").toString(),
      MessageBody: messageBody,
      Author: localStorage.getItem("userMobile"),
      AuthorName: name,
      DateCreated: date.toISOString(),
      AuthorRole: role,
      Attributes: {
        isVisible: true,
        message_reply_index: selectedMessage.MessageIndex,
        message_id: selectedMessage.MessageSid,
        message_reply_media: selectedMessage.mesaage_media,
        uploadLocalId: localId,
        hospital_id: hospital_id,
        userId: userId,
        authorName: name,
        authorRole: role,
        message_type: selectedMessage.messageType,
        has_media: false,
        appointment_id: appointmentId,
        lead_id: leadId,
        patient_name: FirstName,
        patientId: patientId,
        age: age,
        appointmentTime: AppointmentStartTime.toString(),
        category: category,
        mention_data: [],
      },
      messageType: "TEXT",
    };
    const reqPayload = {
      appointmentId: appointmentId,
      userId: userId,
      role,
      templateId: selectedTemplate.templateId,
      mobileNumber: localStorage.getItem("userMobile"),
      patientId: patientId,
      lead_id: leadId,
      hospitalId: hospital_id,
      channelSid: sid,
      authorName: name,
    };
    Mixpanel.track(constants.SEND_TEMPLATE, {
      hospital_name: friendly_name,
      template_name: selectedTemplate.label,
    });

    dispatch(chatBoxActions.sendChatTemplate(reqPayload, addMessagePayload));
  };
  const downloadChatMessage = (url, id, urls) => {
    const { hospital_id } = hospitals.currentHospitalData;
    const { user } = authentication;
    const { appointmentId, leadId, patientId } = patients.patientInfo;
    const { AppointmentStartTime, FirstName, category, age } =
      patients.patientInfo.allInfo;
    const { userId, name, SRNumber } = authentication.user;
    const { role } = authentication.user.roles;
    let localId = generateLocalId(30);
    //  const uploadLocalId=""

    // setLoader(true);
    var date = new Date();
    if (urls) {
      dispatch(
        chatBoxActions.downloadChatMessage(
          { urls: urls, isDownload: false },
          id,
          showGeneralChatMessages,
          privateChatMessagesActive
        )
      );
    } else {
      dispatch(
        chatBoxActions.downloadChatMessage(
          { urls: [url], isDownload: false },
          id,
          showGeneralChatMessages,
          privateChatMessagesActive
        )
      );
    }
    dispatch(
      chatBoxActions.loadingDownloadChatMessage(id, showGeneralChatMessages, privateChatMessagesActive)
    );
  };

  const showChatMedia = (urls, type) => {
    if (type?.includes("image")) {
      setDocType("image");
      type = "image";
    } else if (type?.includes("video")) {
      setDocType("video");
      type = "video";
    } else if (type?.includes("csv")) {
      setDocType("csv");
      type = "csv";
    } else if (
      type?.includes("ms") ||
      type?.includes("word") ||
      type?.includes("doc")
    ) {
      setDocType("doc");
      type = "doc";
    } else {
      setDocType("pdf");
      type = "pdf";
    }
    setShowChatMediaDialog(true);
    setPdfSrc(null);
    setDocSrc(null);
    setImgSrc(null);
    setVideoSrc(null);
    // console.log('minion this is the url',url)
    if (type === "image") {
      setImgSrc(urls[0]);
    } else if (type === "video") {
      setVideoSrc(urls[0]);
    } else if (type === "csv") {
      setDocSrc(urls[0]);
    } else if (type === "doc") {

      // let docURL = "";
      // if (urls[0].includes("view.officeapps.live")) {
      //   docURL = urls[0];
      //   // setDocSrc(urls[0]);
      // } else {
      //   docURL =
      //     "https://view.officeapps.live.com/op/embed.aspx?src=" + urls[0];
      //   // setDocSrc(
      //   //   "https://view.officeapps.live.com/op/embed.aspx?src=" +
      //   //     encodeURIComponent(urls[0])
      //   // );
      // }
      setShowChatMediaDialog(false);
      urls.forEach((element) => {
        // console.log('setShowChatMediaDialog', element)
        const aElem = document.createElement("a");
        aElem.href = element;
        aElem.setAttribute("target", "_blank");
        aElem.click();
      });

    } else {
      setPdfSrc(urls[0]);
    }
    var arr = [];
    if (type === "doc") {

      for (let i = 0; i < urls.length; i++) {
        let temp = "";

        if (urls[0].includes("view.officeapps.live")) {
          temp = urls[i];
        } else {
          temp =
            "https://view.officeapps.live.com/op/embed.aspx?src=" +
            encodeURIComponent(urls[i]);
        }
        arr.push(temp);
      }
      setMediaUrlsList(arr);
    } else {
      setMediaUrlsList(urls);
      { console.log('these are preview urls list updated', urls) }
    }
    setSelectedDocIdx(0);
  };

  const sendToInsurance = ({insFile}) => {
    const { hospital_id } = hospitals.currentHospitalData;
    const { user } = authentication;
    const { appointmentId, leadId, patientId } = patients.patientInfo;
    const { AppointmentStartTime, FirstName, category, age } = patients.patientInfo.allInfo;
    const { userId, name, SRNumber } = authentication.user;
    const { role } = authentication.user.roles;
    const { selectedMessage } = chatBox;
    let localId = generateLocalId(30);

    //  const uploadLocalId=""
    // setLoader(true);
    var date = new Date();
    const { Key = "", SubKey = "" } = insFile;
    const {
      Attributes: { multiUrl },
      DocumentKey,
      Media
    } = selectedMessage;
    var uls=multiUrl?multiUrl:[Media]
    dispatch(
      chatBoxActions.sendToInsurance({
        appointmentId: appointmentId,
        leadId: leadId,
        Key: insFile.Key,
        SubKey: SubKey,
        urls: uls,
      })
    );
    setShowInsurancePopup(false);
    handleClick4();
    setAlertMessage(`Updated Successfully`);
  };

  const printImage = () => {
    // Create a fake iframe
    const iframe = document.createElement("iframe");

    // Make it hidden
    iframe.style.height = 0;
    iframe.style.visibility = "hidden";
    iframe.style.width = 0;

    // Set the iframe's source
    iframe.setAttribute("srcdoc", "<html><body></body></html>");

    document.body.appendChild(iframe);

    iframe.contentWindow.addEventListener("afterprint", function () {
      iframe.parentNode.removeChild(iframe);
    });

    iframe.addEventListener("load", function () {
      // Clone the image
      const image = document.getElementById("downloadableImage").cloneNode();
      image.style.maxWidth = "100%";

      // Append the image to the iframe's body
      const body = iframe.contentDocument.body;
      body.style.textAlign = "center";
      body.appendChild(image);

      image.addEventListener("load", function () {
        // Invoke the print when the image is ready
        iframe.contentWindow.print();
      });
    });
  };
  const isDeleteExpired = (DateCreated) => {
    // 5 mins delete check
    const currentDate = moment();
    const oldDate = moment(DateCreated);
    const duration = currentDate.diff(oldDate, "minutes");

    return duration > 5;
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const open2 = Boolean(showOptions);
  const id2 = open2 ? "simple-popover2" : undefined;

  useEffect(() => {
    var temp = [];
    for (let i = 0; i < data?.length; i++) {
      let item = data[i];
      if (item.sendToInsDash == true) temp.push(data[i].title);
    }
    setSendToInsuranceList(temp);
  }, []);
  useEffect(() => {
    const { role } = authentication.user.roles;
    const { appointmentId } = patients.patientInfo;
    const { templateUsers } = config.versionConfigData.result[0];

    if (templateUsers?.includes(role) && !showGeneralChatMessages) {
      dispatch(chatBoxActions.getChatTemplates(appointmentId));
    }
    if (document.getElementById("inputText")?.innerText)
      document.getElementById("inputText").innerText = null;
  }, [patients.patientInfo]);
  useEffect(() => {
    if (document.getElementById("inputText")?.innerText)
      document.getElementById("inputText").innerText = null;
    setShowReplyMessageRef(false);
  }, [patients.patientInfo, showGeneralChatMessages]);

  const showReplyMessageRef = useRef(showReplyMessage);

  const setShowReplyMessageRef = (data) => {
    showReplyMessageRef.current = data;
    setShowReplyMessage(data);
  };

  // send message on enter click -----
  const handleChatInputSubmit = (e) => {
    e?.preventDefault();
    return
  };

  //auto focus -------
  useEffect(() => {
    if (chatInputRef) {
      chatInputRef?.current?.focus();
    }
  }, [showSpinningLoader]);



  const retrySendMessage = (item,showGeneralChatMessages,privateChatMessagesActive) => {
    // debugger
    dispatch(chatBoxActions.retrySendMessage(item,showGeneralChatMessages,privateChatMessagesActive));
  };

  const handleUploadAnotherDocument = () => {
    if (docType === "image") {
      document.getElementById("uploadimage").click();
    } else if (docType === "video") {
      document.getElementById("uploadvideo").click();
    } else if (docType === "pdf") {
      document.getElementById("uploadpdf").click();
    } else if (docType === "doc") {
      document.getElementById("uploaddoc").click();
    } else if (docType === "excel") {
      document.getElementById("uploadexcel").click();
    } else if (docType === "csv") {
      document.getElementById("uploadcsv").click();
    }
  };

  const fetchMoreMessages = () => {
    let reqBody = {};

    if (showGeneralChatMessages) {
      const { hospital_id, sid } = hospitals.currentHospitalData;
      let size = 0;
      const lastMessageIndex = sortedMessages[size]?.MessageIndex;
      reqBody = {
        channelSid: sid,
        lastMsgIndex: lastMessageIndex - 1,
        limit: chatBoxConstants.MESSAGES_LIMIT,
      };
    } else if(privateChatMessagesActive){
      let size = 0;
      const lastMessageIndex = sortedMessages[size]?.MessageIndex;
      
      reqBody = {
        appointmentId:patients.patientInfo?.appointmentId,
        numberOfMessages: chatBoxConstants.MESSAGES_LIMIT,
        lastMsgIndex: lastMessageIndex - 1,
        leadId: leadId,
        chatType:"private"
      }
    }
    else {
      let size = 0;
      const { appointmentId, leadId } = patients.patientInfo;
      const lastMessageIndex = sortedMessages[size]?.MessageIndex;
      reqBody = {
        appointmentId: appointmentId,
        numberOfMessages: chatBoxConstants.MESSAGES_LIMIT,
        lastMsgIndex: lastMessageIndex - 1,
        leadId: leadId
      };
    }

    if (hasMoreMessages)
      dispatch(
        chatBoxActions.fetchMoreMessages(reqBody, showGeneralChatMessages)
      );
  };

  const scrollToChat = (item) => {
    let id = item?.Attributes?.message_reply_index;
    let element = document.getElementById(id);
    var topPos = element?.offsetTop;
    document.getElementsByClassName("scrollableDiv")[0].scrollTop =
      topPos;
    element.classList.add("highlight");
    setTimeout(() => {
      element.classList.remove("highlight");
    }, 1000);
  };

  const printDocument = () => {
    if (docType === "image") {
      printImage();
    }
    // if(docType=='pdf'){
    //   var iframe = document.getElementById('pdfIframe');
    //   document.getElementById('pdfIframe').contentWindow.print();

    // }
    // if(docType === "doc" || docType === "excel" ){
    //   var iframe = document.getElementById('docIframe');
    //   var innerDoc = iframe?.contentDocument || iframe?.contentWindow.document;

    // }
  };

  useEffect(() => {
    if (document.getElementById("inputText")) {
      document.getElementById("inputText").addEventListener("copy", (e) => {
        var selectedText = window.getSelection();
        var range = selectedText.getRangeAt(0);
        var selectedTextReplacement = range.toString();
        e.clipboardData.setData("text/plain", selectedTextReplacement);
        e.preventDefault(); // default behaviour is to copy any selected text
      });
      // Paste fix for contenteditable
      document.getElementById("inputText").addEventListener("paste", (e) => {
        e.preventDefault();
        let content;
        if (window.clipboardData) {
          content = window.clipboardData.getData("Text");
          if (window.getSelection) {
            var selObj = window.getSelection();
            var selRange = selObj.getRangeAt(0);
            selRange.deleteContents();
            selRange.insertNode(document.createTextNode(content));
          }
        } else if (e.clipboardData) {
          content = (e.originalEvent || e).clipboardData.getData("text/plain");
          document.execCommand("insertText", false, content);
        }
      });
    }
  }, []);


  useEffect(() => {
    if (messageInputText.length > 0)
      sendMessage();
  }, [messageInputText]);

  const sendMessageFromMessageInput = async (input, mentions) => {
    await setMentionData(mentions);
    setMessageInputText(input);
    console.log('IIII',input)
    setTimeout(() => {
      checkScroll()
    }, 0);
  };

  const setSelectedMessage = (item) => {
    dispatch(chatBoxActions.setSelectedMessage(item));
  };
  const navigate = useNavigate();

  const [scrollHeight, setScrollHeight] = useState(500)
  const [chatBoxHeight, setChatBoxHeight] = useState(500)
  const [wrapperHeight, setwrapperHeight] = useState(500)
  const [replyBoxHeight, setReplyBoxHeight] = useState(500)
  let headerHeight = 68

  const { windowSize: { width, height } } = useWindowSize()
  const chatWrapperRef = useRef(undefined)
  const chatBoxRef = useRef(undefined)
  const chatReplyRef = useRef(undefined)

  async function findViewSize() {
    let size = 0;
    let chatBoxSize = chatBoxRef.current?.offsetHeight
    let wrapperSize = chatWrapperRef.current?.offsetHeight
    let replyBoxSize = chatReplyRef.current?.offsetHeight
    setChatBoxHeight(chatBoxSize)

    if (!isNaN(wrapperSize)) {
      size = wrapperSize
    }
    if (!isNaN(chatBoxSize)) {
      size -= chatBoxSize
    }
    if (!isNaN(replyBoxSize)) {
      size -= replyBoxSize
    }
    size -= headerHeight
    setScrollHeight(size)
  }

  useEffect(() => {
    findViewSize()
  })
  useEffect(() => {
    const browserName = detectBrowser()
    // if(sortedMessages?.length){
    //   let loggedInUser = false;
    //   const loggedUserNumber = localStorage.getItem("userMobile");
    //   if (loggedUserNumber === sortedMessages[0].Author) {
    //     const el= document.getElementsByClassName("scrollableDiv")[0]
    //     if(el){
    //       el.style.overflow = 'auto';
    //       window.requestAnimationFrame(() => el.scrollTo({ top: -1, behavior: 'smooth' }));
    //     }
    //   }
    // }
    if (!sortedMessages || sortedMessages.length == 0) {
      dispatch(chatBoxActions.hasMoreMessages(false));
    }
  }, [sortedMessages?.length, patientInfo])

  const handleDirectPreviewDocs = ({ itemDetails, unsignedUrls, title }) => {
    var urlList = unsignedUrls

    const docType = getTypeOfUrl({ url: urlList[0] })
    setPreviewDocumentTitle(title)
    setPreviewDocumentDocType(docType)
    // var 
    dispatch(configActions.showSpinningLoader())
    chatBoxService.downloadChatMessage({ urls: urlList, isDownload: false }).then((res) => {
      dispatch(configActions.hideSpinningLoader())
      if (res.result) {
        var temp = res.result?.map(item => {
          if (docType === 'doc' || docType === 'excel') {
            return 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(item)
          } else
            return item
        })
        setPreviewDocumentUrls(temp)
        setPreviewDocumentFiles(urlList)
        setIsShowPreviewDocument(true)
      }
    })

  }

  const changeMobilePatientScreen = ()=>{
    // const appointment_id = patients.patientInfo.appointmentId;
    changeScreen(dispatch, navigate, { hospitalList: false, patientList: true, chatBox: false }, 'patients')
    const initialPatientInfoState =
    getInitialState("patients")["patientInfo"];
    dispatch(patientActions.setPatientInfo(initialPatientInfoState));
    // appointment_id && dispatch(patientActions.updatePatientsReadCount({ appointment_id, count: 0 }))
  }
  const handlePrivateChat =()=>{

      let params={
        appointmentId:patients.patientInfo.appointmentId,
        leadId:patients.patientInfo.leadId,
        numberOfMessages: chatBoxConstants.MESSAGES_LIMIT,
        chatType:"private"
      }

      cacheAndDispatch({
        action: chatBoxActions.privateChatOn,
        params,
        cacheOf: mainCacheConstants.privateChatMessagesCache,
        key: patients.patientInfo.appointmentId
      });
    
  }

  const closePrivateChatDialog = () =>{
    setPrivateChat(false)
    let privatePopupPaylaod = {
      action: "privateChatEvent",
      payload:{
        privateChatFlag:true
      }
    }
    sendSocketMessage(privatePopupPaylaod)
  }
  return (
    <>
      <Box className={`chat ${ privateChatMessagesActive && "private_chat"}`} ref={chatWrapperRef} data-component="ChatBox">
        <Box sx={{ display: 'flex' }}>
          {isDrawer.hospital && showChatBox &&
            <Box className={isDrawer.hospital ? 'drawerexpand' : 'drawercollapse'} sx={{ background: "#3D7CC9", padding: '8px', textAlign: 'center', borderRadius: '0 0 8px 8px' }} onClick={() => dispatch({ type: hospitalConstants.OPEN_DRAWER })}>
              <p className="drawer-title">H</p>
              <img className="drawer-icon" src={rightArrow} />
            </Box>}
          {isDrawer.patient && showChatBox &&
            <Box className={isDrawer.patient ? 'drawerexpand' : 'drawercollapse'} sx={{ background: "#FFBE00", padding: '8px', textAlign: 'center', borderRadius: '0 0 8px 8px' }} onClick={() => dispatch({ type: hospitalConstants.OPEN_DRAWER })}>
              <p className="drawer-title">P</p>
              <img className="drawer-icon" src={rightArrow} />
            </Box>}
          <Box className="chat__header" sx={{ height: '60px', borderRadius: ['0 0 8px 8px', '0 0 8px 8px', '0 0 10px 10px'], width: '100%' }}>
            <IconButton
              onClick={handleChatInfoClick}
              disabled={showGeneralChatMessages}
              sx={{ display: ['none', 'none', 'block'] }}
            >

              <Avatar
                sx={{
                  width: 38,
                  height: 38,
                  fontSize: "1.1rem",
                  color: "#3D7CC9",
                  bgcolor: "#fff",
                }}
              >
                {Boolean(FirstName?.length)
                  ? showGeneralChatMessages
                    ? getInitials(friendly_name)
                    : getInitials(FirstName)
                  : null}
              </Avatar>
            </IconButton>

            {<Box component="span" sx={{ display: ['block', 'block', 'none'], visibility: blockChatBox && isSmallScreen ? 'hidden' : "unset", cursor: "pointer" }} 
            onClick={() => changeMobilePatientScreen()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12.414" height="22.828" viewBox="0 0 12.414 22.828">
              <path id="Path_6109" data-name="Path 6109" d="M10,20,0,10,10,0" transform="translate(1 1.414)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </svg>
            </Box>}

            <Box className="chat__headerInfo" sx={{ position: "absolute", marginLeft: ['30px', '30px', "55px"] }}>
              <h3>{showGeneralChatMessages ? friendly_name : FirstName}</h3>
            </Box>
            <Container className="chat__headerRight"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                m: "0 !important",
                p: "0 !important",
              }}
            >
              { isInternalUser &&
              <div className="icon_btn_item" ref={priavteChatIconRef}>
                {privateChatMessagesActive?
                <IconButton onClick={()=>dispatch(chatBoxActions.privateChatOff())} sx={{ padding: "0px!important" }}>
                  <Box component="img" sx={{ height: ["40px", "40px", "50px"], width: ["40px", "40px", "50px"] }} src={privateModeOff}></Box>
                </IconButton>
                  :
                <IconButton onClick={()=>handlePrivateChat()} sx={{ padding: "0px!important" }}>
                  <Box component="img" sx={{ height: ["40px", "40px", "50px"], width: ["40px", "40px", "50px"] }} src={privateModeOn}></Box>
                </IconButton>
                }
              </div>}
              <div className="icon_btn_item">
                <IconButton onClick={handleDocumentsClick} sx={{ padding: "0px!important" }}>
                  <Box component="img" sx={{ height: ["40px", "40px", "50px"], width: ["40px", "40px", "50px"] }} src={allDocsIcon}></Box>
                </IconButton>
              </div>
              <div className="icon_btn_item">
                {!showGeneralChatMessages && (
                  <IconButton
                    disabled={showGeneralChatMessages}
                    onClick={handleChatInfoClick}
                    sx={{ padding: "0px!important", display: blockChatBox && isSmallScreen ? 'none' : "flex" }}
                  >
                    <Box component="img" sx={{ height: ["40px", "40px", "50px"], width: ["40px", "40px", "50px"] }} src={infoIcon}></Box>
                  </IconButton>
                )}
              </div>
            </Container>
          </Box>
        </Box>
        <Box id="chat_body_main" className="chat_body_main" sx={{ position: 'relative', height: `${scrollHeight}px` }}>
          {patientChatMessagesActive || GeneralChatMessagesActive ? (
            <>
              {!patientInfo.allInfo.feedbackData && !!patientInfo.allInfo?.dischargeStatus && EXTERNAL_HOSPITAL_ROLES?.includes(role) &&
               <Feedback />
               }
              {/* <InfiniteScroll
                dataLength={sortedMessages.length} //This is important field to render the next data
                next={fetchMoreMessages}
                height={scrollHeight}
                className="scrollableDiv"
                hasMore={hasMoreMessages}
                loader={
                  <div className={"item"}>
                    <Loader
                      type="spinner-default"
                      bgColor={"blue"}
                      color={"blue"}
                      size={40}
                    />
                  </div>
                }
                style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                inverse={true}
              > */}

              {/* chat messages */}
              {sortedMessages &&sortedMessages.length>0&&
                <CustomInfiniteScroller fetchMoreData={fetchMoreMessages} inverse={true} hasMore={hasMoreMessages}>
                  {sortedMessages?.map((item, idx) => {
                    let {
                      Author,
                      AuthorName,
                      MessageBody,
                      DateCreated,
                      AuthorRole,
                      Attributes,
                      Attributes: { isVisible, message_reply_text, document_key },
                      hasMedia,
                      Media,
                      MessageSid,
                      isSync,
                      Retry = false,
                    } = item;

                    let loggedInUser = false;
                    const loggedUserNumber = localStorage.getItem("userMobile");

                    if (loggedUserNumber === Author) {
                      loggedInUser = true;
                      AuthorName = "You";
                    }

                    return (
                      <div
                        className={`chat__body ${loggedInUser ? "chat__SideChange" : ""}`}
                        id={item?.MessageIndex}
                        key={item?.MessageSid+"+"+idx }
                      >
                        <Box sx={{ maxWidth: ['220px', '220px', '28rem'], minWidth: ['220px', '220px', '23rem'] }} className="chatTemplate">
                          {/* additional actions on message */}
                          {selectedMsgId == item?.MessageSid && (
                            <Popover
                              id="messageOptions"
                              open={open2}
                              anchorEl={showOptions}
                              onClose={() => handleClose2()}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}

                              PaperProps={{
                                sx: {
                                  borderRadius: '8px',
                                  boxShadow: '0px 0px 6px #00000029'
                                }
                              }}
                            >
                              <Box sx={{ padding: '5px 20px 15px 20px', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                {options.map((option, index) => {
                                  //check to enable the user to delete message of himself only

                                  const isDeleteOption = option === "Delete";
                                  const isReplyOption = option === 'Reply'
                                  const isSelf = Boolean(
                                    loggedUserNumber === Author
                                  );

                                  if (isDeleteOption) {
                                    if (!isSelf) return <></>;
                                    if (isSelf && isDeleteExpired(DateCreated))
                                      return <></>;
                                  }
                                  if (isReplyOption) {
                                    if (selectedMessage.Retry === true)
                                      return <></>
                                  }

                                  return (
                                    <Box
                                      onClick={() =>
                                        messageActions(index, item)
                                      }
                                      className="options-btn__option"
                                      key={index}
                                      sx={{
                                        color: index === 1 ? "#666A70" : "#fff",
                                        display: "flex",
                                        alignItems: "center",
                                        background: index === 0 ? '#3D7CC9' : '#F5F5F5',
                                        boxShadow: " 0px 3px 6px #00000029",
                                        borderRadius: '6px',
                                        marginTop: '10px',
                                        padding: "0.21em 2em",
                                        gap: ".3em",
                                      }}
                                    >
                                      {index === 1 && (
                                        <img src={deleteIcon} />

                                      )}
                                      {index === 0 && <ReplyAllIcon style={{ maxWidth: '22.5px' }} />}

                                      <Typography sx={{ fontSize: ['14px', '14px', '16px'], fontWeight: 600 }}>{option}</Typography>
                                    </Box>
                                  );
                                })}
                                {/* {console.log('docTggers', docTaggers, 'and this is role', role)} */}
                                {
                                  hasMedia == true
                                  && docTaggers.includes(role) &&
                                  !(item.Retry === true) &&
                                  // documentList?.find(
                                  //   (key) => key.Key ===document_key
                                  // )?.sendToInsDash == true && 
                                  (
                                    <Box
                                      onClick={() => messageActions(2, item)}

                                      className="options-btn__option"
                                      sx={{
                                        color: "#fff",
                                        display: "flex",
                                        alignItems: "center",
                                        background: '#3D7CC9',
                                        boxShadow: " 0px 3px 6px #00000029",
                                        borderRadius: '6px',
                                        marginTop: '10px',
                                        padding: "0.21em 2em",
                                        gap: ".3em",
                                      }}
                                    >
                                      <Typography sx={{ fontSize: ['14px', '14px', '16px'], fontWeight: 600 }}>Send to Insurance</Typography>

                                    </Box>
                                  )}
                              </Box>



                            </Popover>
                          )}
                          {/* show deleted message */}
                          { (
                            <ChatMessage
                              item={item}
                              idx={idx}
                              id2={id2}
                              selectedMsgId={selectedMsgId}
                              setSelectedMessage={setSelectedMessage}
                              scrollToChat={scrollToChat}
                              retrySendMessage={retrySendMessage}
                              downloadChatMessage={downloadChatMessage}
                              showChatMedia={showChatMedia}
                              showGeneralChatMessages={
                                showGeneralChatMessages
                              }
                              generalGroupEditors={generalGroupEditors}
                              role={role}
                              handleClick2={handleClick2}
                              setSelectedMsgId={setSelectedMsgId}
                              messageActions={messageActions}
                              handleUploadDocumentClick={handleUploadDocumentClick}
                              handleDirectPreviewDocs={handleDirectPreviewDocs}
                            />
                          )}
                        </Box>
                      </div>
                    );

                  })
                  }

                { privateChatMessagesActive && <p className="privateChatMessageInfo"> You are sending a message privately </p>}        
                { !pxRoleChatAllowed && <p className="pxRoleChatMessages"> You are not allowed to message in this chat, Kindly use private chat. </p>}
                </CustomInfiniteScroller>
              }
              {
                (!sortedMessages||sortedMessages.length==0) &&
                <>
                <Container
                  disableGutters
                  sx={{
                    height: "100%",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <Typography variant="h5">No messages to show !</Typography>
                </Container>
                { privateChatMessagesActive && <p className="privateChatMessageInfo"> You are sending a message privately </p>}
                
                </>
              }
              {/* </InfiniteScroll> */}
              {endOfChatArr && endOfChatArr.includes(appointmentId) && (
                <div className="endOfChat">
                  <sub>********</sub>
                  <span>END OF CHAT</span>
                  <sub>********</sub>
                </div>
              )}
              {/* {console.log('unread count render',unreadCount)} */}
            {unreadCount>0 && 
                  <div className='unreadMessage'> You have {unreadCount} unread {unreadCount===1?'message':'messages'} </div>
                }
            </>
          ) : (
            <Grid
              container
              height="100%"
              sx={{
                placeContent: "center",
              }}
            >
              <Grid item>
                <CircularProgress
                  size="50px"
                  sx={{
                    color: "#444",
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        {/* show reply message */}
        {showReplyMessage === true && (
          <Box className="replyMessage" ref={chatReplyRef}
            sx={{
              background: "#06263e",
              display: "flex",
              zIndex: 9,
              borderRadius: '10px 10px 0px 0px',
              p: '10px 10px 10px 20px',
              marginTop: "1px"
            }}
          >
            <Card
              sx={{
                display: "flex",
                flex: 3,
                justifyContent: "space-between",
                background: "#e4e4e4",
                borderRadius: "8px",
                maxHeight: '68px',
                backgroundColor: '#fff',
                overflow: 'hidden',
                paddingBottom: '2px'
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px 10px",
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    padding: "0px!important",
                    paddingLeft: "10px",
                  }}
                  style={{ padding: "0px!important" }}
                >
                  <Typography component="div" sx={{ fontSize: "10px", color: '#666A70', lineHeight: 1.5, mb: '5px' }}>
                    {selectedMessage.AuthorName} <Box component={`span`}>({role})</Box>
                  </Typography>
                  <Typography
                    component="div"
                    sx={{ color: '#00263E', fontSize: '14px', lineHeight: '18px' }}
                  >
                    <MessageText message={selectedMessage} />
                  </Typography>
                </CardContent>
              </Box>
              {selectedMessage.hasMedia && (
                <div>
                  {selectedMessage.mediaUrl &&
                    selectedMessage?.MediaType?.includes("image") && (
                      <div data-type={docType} >
                        <CardMedia
                          component="img"
                          sx={{ width: 60, height: 60 }}
                          image={
                            selectedMessage.mediaUrl &&
                            selectedMessage.mediaUrl[0]
                          }
                          alt="no preview available"
                        />
                      </div>
                    )}
                  {selectedMessage.mediaUrl &&
                    selectedMessage?.MediaType?.includes("video") && (
                      <div data-type={docType}>
                        <CardMedia
                          component="video"
                          sx={{ width: 60, height: 60 }}
                          src={
                            selectedMessage.mediaUrl &&
                            selectedMessage.mediaUrl[0]
                          }
                          alt="no preview available"
                        />
                      </div>
                    )}
                  {selectedMessage.mediaUrl &&
                    !selectedMessage.MediaType?.includes("image") && (
                      <div>
                        <ArticleIcon
                          style={{ height: "60px", width: "60px" }}
                        />
                      </div>
                    )}

                  {selectedMessage.mediaUrl &&
                    !selectedMessage.MediaType?.includes("video") && (
                      <div>
                        <ArticleIcon
                          style={{ height: "60px", width: "60px" }}
                        />
                      </div>
                    )}

                  {!selectedMessage.mediaUrl && (
                    <div>
                      <ArticleIcon
                        style={{ height: "60px", width: "60px" }}
                      />
                    </div>
                  )}
                </div>
              )}
            </Card>
            <IconButton
              aria-label="close"
              onClick={() => setShowReplyMessageRef(false)}
              sx={{
                marginLeft: "10px",
                color: "#FFFFFF",
                backgroundColor: "#06263e",
                width: "24px",
                height: "24px",
                alignSelf: 'center',
                "&.MuiButtonBase-root:hover": {
                  // bgcolor: "#ff8300",
                },
              }}
            >
              <img src={crossIconOrange} alt="close" />
            </IconButton>
          </Box>
        )}
        {pxRoleChatAllowed &&
        <> {(showGeneralChatMessages && generalGroupEditors?.includes(role)) ||
          (endOfChatArr && !endOfChatArr.includes(appointmentId)) ? (
          <>
            <Box className={`chat__footer ${privateChatMessagesActive && "chat__footer__private"}`} ref={chatBoxRef} id="chat__footer" sx={{ minHeight: '60px', width: ["100%", "100%", "unset"], borderRadius: showReplyMessage ? '0px 0px 0px 0px' : '10px 10px 0px 0px', position: 'absolute', bottom: 0, left: 0, right: 0 }}>
              <div>
                <Button
                  aria-describedby={id}
                  style={{ minWidth: "50px" }}
                  onClick={(e) => handleClick(e)}
                  sx={{ visibility: showReplyMessage ? 'hidden' : 'unset' }}
                >
                  { privateChatMessagesActive?
                  <Box component="img" src={privateAttachment}></Box>:
                  <svg style={{ pointerEvents: "none" }} xmlns="http://www.w3.org/2000/svg" width="20" height="11" viewBox="0 0 20 11">
                    <path id="Icon_material-attachment" data-name="Icon material-attachment" d="M3,16a5.5,5.5,0,0,1,5.5-5.5H19a4,4,0,1,1,0,8H10.5a2.5,2.5,0,0,1,0-5H18v2H10.41c-.55,0-.55,1,0,1H19a2,2,0,1,0,0-4H8.5a3.5,3.5,0,1,0,0,7H18v2H8.5A5.5,5.5,0,0,1,3,16Z" transform="translate(-3 -10.5)" fill="#fff" />
                  </svg>}
                </Button>
              </div>

              <div style={{ width: "100%", position: "relative" }}>
              {!isSmallScreen && <MessageInput
                  ref={childRef}
                  sendMessageFromMessageInput={sendMessageFromMessageInput}
                  onDomUpdate={() => {
                    findViewSize()
                  }}

                />
                }
                {
                  isSmallScreen && <MobileMessageInput ref={childRef}
                    sendMessageFromMessageInput={sendMessageFromMessageInput}
                    setIsRecording={setIsRecording}
                    isRecording={isRecording}
                    onDomUpdate={() => {
                      findViewSize()
                    }} />
                }
              </div>
              <div style={{ minWidth: "50px", textAlign: "center" }}>
                <SendIcon
                  id="sendIcon"
                  disabled={isRecording}
                  sx={{ fontSize: "30px", width: "22px", cursor: 'pointer',color: privateChatMessagesActive ?"#3D7CC9":"#ffff", }}
                  onClick={() => {
                    if(isRecording) return;
                    childRef.current.extractAndClearEditor()
                  }}
                />
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                className="popOver "
              >

                <Tooltip title="Upload excel" placement="right" arrow>
                  <div
                    onClick={() => setDocType("excel")}
                    style={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "50%",
                      backgroundColor: "#3376cf",
                      alignItems: "center",
                      marginBottom: ".5rem",
                    }}
                  >
                    <label htmlFor="uploadexcel">
                      <svg
                        style={{
                          height: "1.8em",
                          transform: "translate(3px, 9px)",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="40.1"
                        height="49.255"
                        viewBox="0 0 40.1 49.255"
                      >
                        <path
                          style={{
                            color: "white",
                            fill: "currentcolor",
                            fontSize: "10px",
                          }}
                          id="xls-file"
                          d="M72.456,0H52.673A5.25,5.25,0,0,0,47.43,5.242V24.627h-.512a2.112,2.112,0,0,0-2.112,2.113V39.551a2.112,2.112,0,0,0,2.112,2.112h.512v2.349a5.248,5.248,0,0,0,5.242,5.242H79.665a5.248,5.248,0,0,0,5.241-5.242V12.407ZM50.184,28.934H52.49l.778,1.621c.264.539.462.976.673,1.476h.026c.211-.567.382-.963.607-1.476l.751-1.621h2.293l-2.5,4.336,2.636,4.548H55.43l-.8-1.608c-.329-.62-.54-1.08-.791-1.6h-.026c-.185.515-.409.976-.686,1.6l-.738,1.608H50.091l2.571-4.5Zm29.482,17.11H52.673a2.033,2.033,0,0,1-2.03-2.032V41.663H75.805a2.112,2.112,0,0,0,2.112-2.112V26.74a2.112,2.112,0,0,0-2.112-2.113H50.643V5.242a2.033,2.033,0,0,1,2.03-2.029l18.582-.019v6.868A3.637,3.637,0,0,0,74.89,13.7l6.728-.019.076,30.333A2.032,2.032,0,0,1,79.665,46.044Zm-15.28-9.913v1.687h-5.55V28.934h2.017v7.2Zm3.4-2.043c-1.476-.514-2.439-1.331-2.439-2.623,0-1.517,1.266-2.676,3.362-2.676a5.456,5.456,0,0,1,2.268.448l-.448,1.621a4.232,4.232,0,0,0-1.859-.422c-.87,0-1.292.4-1.292.857,0,.567.5.818,1.648,1.252,1.569.58,2.307,1.4,2.307,2.65,0,1.489-1.147,2.755-3.586,2.755a5.811,5.811,0,0,1-2.518-.541l.409-1.66a5.121,5.121,0,0,0,2.227.552c.923,0,1.41-.382,1.41-.961C69.279,34.787,68.857,34.47,67.79,34.088Z"
                          transform="translate(-44.806)"
                          fill="#3D7CC9"
                        />
                      </svg>

                      {/* <ArticleIcon
                    sx={{ fontSize: "30px", mr: "10px" }}
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                  /> */}
                    </label>
                    <input
                      id="uploadexcel"
                      onChange={() => {
                        uploadDocument();
                        setSelectedFileProps([]);
                      }}
                      // onClick={() => setUploadedDocs([])}
                      type="file"
                      multiple
                      accept=".xlsx"
                      style={{ visibility: "hidden", width: "0px" }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Upload Csv" placement="right" arrow>
                  <div
                    onClick={() => setDocType("csv")}
                    style={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "50%",
                      backgroundColor: "#965ec7",
                      alignItems: "center",

                      marginBottom: ".5rem",
                    }}
                  >
                    {/* <svg
                   */}
                    <label htmlFor="uploadcsv">
                      <svg
                        style={{
                          height: "1.8em",
                          transform: "translate(3px, 9px)",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="40.1"
                        height="49.257"
                        viewBox="0 0 40.1 49.257"
                      >
                        <path
                          style={{
                            color: "white",
                            fill: "currentcolor",
                            fontSize: "10px",
                          }}
                          id="Path_293"
                          data-name="Path 293"
                          d="M403.449,487.48H383.665a5.249,5.249,0,0,0-5.242,5.243v19.386h-.511a2.114,2.114,0,0,0-2.113,2.113v12.813a2.114,2.114,0,0,0,2.113,2.113h.511v2.347a5.25,5.25,0,0,0,5.245,5.243h26.99a5.249,5.249,0,0,0,5.241-5.243V499.887ZM383.5,523.2a1.955,1.955,0,0,0,1.566.709,1.787,1.787,0,0,0,1.224-.452,2.64,2.64,0,0,0,.735-1.421l1.729.548a3.886,3.886,0,0,1-1.323,2.147,4.162,4.162,0,0,1-5.238-.5,4.59,4.59,0,0,1-1.134-3.285,4.832,4.832,0,0,1,1.139-3.425,3.907,3.907,0,0,1,3-1.219,3.669,3.669,0,0,1,2.632.956,3.5,3.5,0,0,1,.9,1.627l-1.766.421a1.822,1.822,0,0,0-1.861-1.481,2.007,2.007,0,0,0-1.593.7,3.45,3.45,0,0,0-.611,2.282A3.709,3.709,0,0,0,383.5,523.2Zm27.167,10.325h-.008v0H383.67a2.028,2.028,0,0,1-2.029-2.028v-2.35H406.8a2.113,2.113,0,0,0,2.113-2.113V514.221a2.113,2.113,0,0,0-2.113-2.113H381.642V492.723a2.028,2.028,0,0,1,2.029-2.029l18.582-.021v6.869a3.636,3.636,0,0,0,3.637,3.637l6.729-.019.076,30.335A2.03,2.03,0,0,1,410.668,533.528Zm-15.621-11.341a1.462,1.462,0,0,0-.641-.382c-.21-.073-.685-.2-1.429-.385a4.836,4.836,0,0,1-2.011-.874,2.292,2.292,0,0,1-.815-1.777,2.266,2.266,0,0,1,.382-1.261,2.4,2.4,0,0,1,1.1-.9,4.423,4.423,0,0,1,1.738-.306,3.733,3.733,0,0,1,2.5.728,2.576,2.576,0,0,1,.882,1.946l-1.784.079a1.506,1.506,0,0,0-.49-.979,1.791,1.791,0,0,0-1.129-.3,2.039,2.039,0,0,0-1.218.319.643.643,0,0,0-.284.548.678.678,0,0,0,.266.536,4.683,4.683,0,0,0,1.639.59,8.671,8.671,0,0,1,1.924.635,2.48,2.48,0,0,1,.976.9,2.62,2.62,0,0,1,.353,1.406,2.593,2.593,0,0,1-.423,1.422,2.515,2.515,0,0,1-1.192.984,4.979,4.979,0,0,1-1.922.323,3.828,3.828,0,0,1-2.572-.774,3.366,3.366,0,0,1-1.073-2.256l1.735-.168a2.115,2.115,0,0,0,.635,1.282,1.915,1.915,0,0,0,1.292.41,1.979,1.979,0,0,0,1.3-.364,1.081,1.081,0,0,0,.437-.852A.8.8,0,0,0,395.047,522.187Zm2.564-5.732h1.934l2.235,6.536,2.163-6.536h1.892l-3.163,8.831h-1.9Z"
                          transform="translate(-375.8 -487.48)"
                          fill="#3D7CC9"
                        />
                      </svg>

                      {/* <ArticleIcon
                    sx={{ fontSize: "30px", mr: "10px" }}
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                  /> */}
                    </label>
                    <input
                      id="uploadcsv"
                      onChange={() => {
                        uploadDocument();
                        setSelectedFileProps([]);
                      }}
                      // onClick={() => setUploadedDocs([])}
                      type="file"
                      multiple
                      accept=".csv"
                      style={{ visibility: "hidden", width: "0px" }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Upload Docs" placement="right" arrow>
                  <div
                    onClick={() => setDocType("doc")}
                    style={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "50%",
                      backgroundColor: "#06263e",
                      alignItems: "center",

                      marginBottom: ".5rem",
                    }}
                  >
                    <label htmlFor="uploaddoc">
                      <svg
                        style={{
                          height: "1.8em",
                          transform: "translate(3px, 9px)",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="40.1"
                        height="49.255"
                        viewBox="0 0 40.1 49.255"
                      >
                        <path
                          style={{
                            color: "white",
                            fill: "currentcolor",
                            fontSize: "10px",
                          }}
                          id="Path_286"
                          data-name="Path 286"
                          d="M72.456,0H52.673A5.25,5.25,0,0,0,47.43,5.242V24.627h-.512a2.112,2.112,0,0,0-2.112,2.113V39.551a2.112,2.112,0,0,0,2.112,2.112h.512v2.349a5.248,5.248,0,0,0,5.242,5.242H79.665a5.248,5.248,0,0,0,5.241-5.242V12.407ZM48.3,28.5a18.1,18.1,0,0,1,2.715-.184,5.721,5.721,0,0,1,3.639.948,3.934,3.934,0,0,1,1.5,3.349,4.5,4.5,0,0,1-1.476,3.6,6.379,6.379,0,0,1-4.1,1.146,17.375,17.375,0,0,1-2.281-.132V28.5ZM79.665,46.044H52.673a2.034,2.034,0,0,1-2.03-2.032V41.663H75.805a2.112,2.112,0,0,0,2.113-2.112V26.74a2.112,2.112,0,0,0-2.113-2.113H50.642V5.242a2.033,2.033,0,0,1,2.03-2.029l18.582-.019v6.868A3.637,3.637,0,0,0,74.89,13.7l6.729-.019.076,30.334A2.032,2.032,0,0,1,79.665,46.044ZM57.071,32.89c0-2.663,1.7-4.653,4.324-4.653,2.729,0,4.218,2.043,4.218,4.5,0,2.913-1.766,4.679-4.363,4.679C58.614,37.411,57.071,35.421,57.071,32.89Zm14.413,2.847a5.034,5.034,0,0,0,1.674-.29l.3,1.568a5.707,5.707,0,0,1-2.28.383c-3.072,0-4.654-1.911-4.654-4.442a4.53,4.53,0,0,1,4.851-4.719,5.256,5.256,0,0,1,2.188.394l-.408,1.6a4.32,4.32,0,0,0-1.687-.33,2.673,2.673,0,0,0-2.834,2.94A2.653,2.653,0,0,0,71.485,35.737Z"
                          transform="translate(-44.806)"
                          fill="#3D7CC9"
                        />
                      </svg>
                    </label>
                    <input
                      id="uploaddoc"
                      onChange={() => {
                        uploadDocument();
                        setSelectedFileProps([]);
                      }}
                      // onClick={() => setUploadedDocs([])}
                      type="file"
                      multiple
                      accept=".doc,.docx"
                      style={{ visibility: "hidden", width: "0px" }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Upload Pdf" placement="right" arrow>
                  <div
                    style={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "50%",
                      backgroundColor: "#ee8135",
                      alignItems: "center",

                      marginBottom: ".5rem",
                    }}
                    onClick={() => setDocType("pdf")}
                  >
                    <label htmlFor="uploadpdf">
                      <svg
                        style={{
                          height: "1.8em",
                          transform: "translate(3px, 9px)",
                        }}
                        id="pdf-file"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40.1"
                        height="48.105"
                        viewBox="0 0 40.1 48.105"
                      >
                        <path
                          style={{
                            color: "white",
                            fill: "currentcolor",
                            fontSize: "10px",
                          }}
                          id="Path_283"
                          data-name="Path 283"
                          d="M120.455,298.458c0-.655-.455-1.046-1.257-1.046a2.785,2.785,0,0,0-.665.063v2.1a2.522,2.522,0,0,0,.539.041C119.927,299.619,120.455,299.187,120.455,298.458Z"
                          transform="translate(-110.906 -267.422)"
                          fill="#3D7CC9"
                        />
                        <path
                          style={{
                            color: "white",
                            fill: "currentcolor",
                            fontSize: "10px",
                          }}
                          id="Path_284"
                          data-name="Path 284"
                          d="M194.533,297.681a3.328,3.328,0,0,0-.729.063V302.4a2.915,2.915,0,0,0,.56.032,2.206,2.206,0,0,0,2.408-2.492A2.041,2.041,0,0,0,194.533,297.681Z"
                          transform="translate(-178.274 -267.249)"
                          fill="#3D7CC9"
                        />
                        <path
                          style={{
                            color: "white",
                            fill: "currentcolor",
                            fontSize: "10px",
                          }}
                          id="Path_285"
                          data-name="Path 285"
                          d="M72.455,0H52.673A5.19,5.19,0,0,0,47.43,5.12V24.053h-.512a2.088,2.088,0,0,0-2.112,2.063V38.628a2.088,2.088,0,0,0,2.112,2.063h.512v2.294a5.188,5.188,0,0,0,5.242,5.12H79.665a5.188,5.188,0,0,0,5.241-5.12V12.118ZM50.348,28.374a16.946,16.946,0,0,1,2.715-.179,4.335,4.335,0,0,1,2.715.695,2.514,2.514,0,0,1,.949,2.008,2.739,2.739,0,0,1-.817,2.06,4.185,4.185,0,0,1-2.887.914,5.327,5.327,0,0,1-.686-.038v3.1h-1.99Zm29.317,16.6H52.673a2.01,2.01,0,0,1-2.03-1.984V40.691H75.805a2.088,2.088,0,0,0,2.113-2.063V26.116a2.088,2.088,0,0,0-2.113-2.063H50.642V5.12a2.01,2.01,0,0,1,2.03-1.981L71.254,3.12V9.828a3.6,3.6,0,0,0,3.636,3.551l6.728-.019.076,29.626A2.009,2.009,0,0,1,79.665,44.97ZM58.011,36.9V28.374a18.519,18.519,0,0,1,2.715-.179,5.822,5.822,0,0,1,3.639.926,3.805,3.805,0,0,1,1.5,3.271,4.351,4.351,0,0,1-1.476,3.514,6.487,6.487,0,0,1-4.1,1.12A17.8,17.8,0,0,1,58.011,36.9ZM72.4,31.851v1.6h-3.19v3.489H67.191V28.259h5.431v1.609H69.207v1.983Z"
                          transform="translate(-44.806)"
                          fill="#3D7CC9"
                        />
                      </svg>
                    </label>
                    <input
                      id="uploadpdf"
                      accept=".pdf"
                      onChange={() => {
                        uploadDocument();
                        setSelectedFileProps([]);
                      }}
                      type="file"
                      multiple
                      style={{ visibility: "hidden", width: "0px" }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Upload Images" placement="right" arrow>
                  <div
                    onClick={() => setDocType("image")}
                    style={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "50%",
                      backgroundColor: "#f8be32",
                      alignItems: "center",
                      marginBottom: ".5rem",
                    }}
                  >
                    <label htmlFor="uploadimage">
                      <ImageIcon
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                          color: "white",
                        }}
                        sx={{
                          fontSize: "30px",
                          mr: "10px",
                          transform: "translate(-2px,-2px)",
                        }}
                      />
                    </label>
                    <input
                      id="uploadimage"
                      accept="image/jpeg,image/jpg,image/png"
                      onChange={() => {
                        uploadDocument();
                        setSelectedFileProps([]);
                      }}
                      type="file"
                      multiple
                      style={{ visibility: "hidden", width: "0px" }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Upload Videos" placement="right" arrow>
                  <div
                    onClick={() => setDocType("video")}
                    style={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "50%",
                      backgroundColor: "#f8be32",
                      alignItems: "center",
                      marginBottom: ".5rem",
                    }}
                  >
                    <label htmlFor="uploadvideo">
                      <VideoFile
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                          color: "white",
                        }}
                        sx={{
                          fontSize: "30px",
                          mr: "10px",
                          transform: "translate(-2px,-2px)",
                        }}
                      />
                    </label>
                    <input
                      id="uploadvideo"
                      accept="video/x-msvideo, .avi, video/x-matroska, .mkv, video/webm, video/mp4"
                      onChange={() => {
                        uploadDocument();
                        setSelectedFileProps([]);
                      }}
                      type="file"
                      multiple
                      style={{ visibility: "hidden", width: "0px" }}
                    />
                  </div>
                </Tooltip>
              </Popover>
            </Box>
            <Box style={{ height: `${chatBoxHeight}px` }} />
          </>
        ) : (
          <>
            <div className={`chat__footer ${privateChatMessagesActive && "chat__footer__private"}`}
              style={{
                background: "#696969",
                color: "white",
                fontWeight: "400",
                justifyContent: "center",
                padding: "10px",
                fontSize: "20px",
              }}
            >
              You are not allowed to message in this chat
            </div>
          </>
        )}</>
            }
      </Box>
      {!showPrivateChatPopup &&
        <DailogHWA
          PaperProps={{ sx: { width: ['300px', '300px', '300px', '300px'], height: ['280px', '280px', '280px', '280px'], margin: ['14% 0 0 0', '6% 0 0 0', '6% 0 0 0'] } }}
          open={isPrivateChat}
          title={'Start Private Chat'}
          closeProps={{ display: 'none' }}
        > <Box class='private-chat-popup-info' ref={highlightPrivateContent}>
            {isMobile ?
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="dotted-line" width="192.619" height="181.009" viewBox="0 0 192.619 181.009">
                <path id="Path_26397" data-name="Path 26397" class="cls-1" d="M109.862-2.3s40.277,77.815,2.222,96.6S.631,55.328-40.133,76.179-50.972,177.7-50.972,177.7" transform="translate(62.842 2.875)" fill="none" stroke="#fff" strokeWidth="2.5px" strokeDasharray="5" />
              </svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="dotted-line" width="914.019" height="230.301" viewBox="0 0 914.019 230.301">
                <path id="Path_26397" dataName="Path 26397" d="M822-2.3s18.492,143.85-174.787,177.193S222.7-5.482,45.564,29.7-79.217,226.914-79.217,226.914" transform="translate(88.973 2.619)" fill="none" stroke="#fff" strokeWidth="5" strokeDasharray="5" />
              </svg>
            }
            <p> Click on this button to chat with Pristyn Care users only </p>
            <p> Messages or documents shared in this mode will not be visible to non Pristyn Care hospital chat users </p>
            <ButtonHWA
              sx={{ fontSize: ['14px', '14px', '14px', '14px', '14px'] }}
              variant='contained'
              onClick={() => closePrivateChatDialog()}
            > Got it </ButtonHWA>
          </Box>
          <div className="icon_btn_item highlight-private-icon" ref={highlightPrivateIcon}>
            <IconButton sx={{ padding: '8px' }}>
              <Box component="img" sx={{ height: ["40px", "40px", "50px"], width: ["40px", "40px", "50px"] }} src={privateModeOn}></Box>
            </IconButton>
          </div>
        </DailogHWA>
      }

      {/* additional overlays */}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open3}
        autoHideDuration={3000}
        onClose={handleClose3}
      >
        <Alert
          onClose={handleClose3}
          severity="error"
          sx={{ width: "100%", fontSize: "18px" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open4}
        autoHideDuration={3000}
        onClose={handleClose4}
      >
        <Alert
          onClose={handleClose4}
          severity="success"
          sx={{ width: "100%", fontSize: "18px" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      {/* show patient info popup */}
      <Dialog
        open={showPatientInfo}
        onClose={() => setShowPatientInfo(false)}
        PaperProps={{
          style: { overflow: "visible", background: "transparent" },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setShowPatientInfo(false)}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: "1",
            transform: "translate(50%, -50%)",
            color: "#FFFFFF",
            backgroundColor: "#fff",
            width: "24px",
            height: "24px",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#fff",
            },
            marginRight: "25px"
          }}
        >
          <img src={crossIconOrange} alt="close" />
        </IconButton>

        <Box
          sx={{
            p: "0 !important",
            pt: "0",
            background: "#fff",
            position: "relative",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h3"
            fontSize="18px"
            textAlign="center"
            py="1rem"
            sx={{
              backgroundColor: "#3D7CC9",
              color: "#fff",
              borderRadius: "8px"
            }}
          >
            Patient Info
          </Typography>

          {patientInfoActive ? (
            !result.length ? (
              <Typography align="center" variant="h4" sx={{ fontSize: ['18px', '1.5rem', '2.125rem'], color: ['#666A70', '#666A70', '#1976d2'], fontWeight: ['600', '600', '700'] }}>
                No Data found!
              </Typography>
            ) : (
              <Box
                sx={{
                  p: "20px",
                  color: "#666A70"
                }}
              >
                {result?.map((item) => {
                  const key = item?.["key"] || "";
                  const label = item?.["label"] || "";
                  const value = item?.["value"] || "";
                  return (
                    <>
                      {value && <Container
                        disableGutters
                        key={uniqid()}
                        sx={{
                          m: "0 0 .5rem 0 !important",
                          display: "grid",
                          gridTemplate: "auto/ auto auto",
                          gap: "2rem",
                          alignItems: "center",
                          justifyContent: "space-beween",
                          pt: ['10px']
                        }}
                      >
                        <Typography
                          variant="h6"
                          color="primary"
                          sx={{ textTransform: "capitalize", fontSize: ['14px', "14px", "1rem"] }}
                        >
                          {label}:
                        </Typography>
                        <Typography
                          sx={{
                            justifySelf: "end",
                            textAlign: "right",
                            fontWeight: "600",
                            fontSize: ['14px', '14px', "1rem"],
                            wordBreak: "break-all!important",
                            maxWidth: "182px"
                            // whiteSpace: "nowrap"
                          }}
                        >
                          {key === "ipdTime"
                            ? moment(value).format("D MMM YYYY, h:mm:ss a")
                            : value}
                        </Typography>
                      </Container>
                      }
                      <Divider sx={{ borderColor: '#F5F5F5' }} />
                    </>
                  );
                })}
              </Box>
            )
          ) : (
            <>
              {/* <Grid
                  container
                  height="50vh"
                  sx={{
                    color: "orangered",
                    placeContent: "center",
                  }}
                >
                  <Grid item>
                    <CircularProgress
                      size="50px"
                      sx={{
                        color: "#444",
                      }}
                    />
                  </Grid>
                </Grid> */}
            </>
          )}
        </Box>
      </Dialog>
      {/* delete message popup */}
      <Dialog
        open={showDeleteMsgPopUp}
        onClose={() => setShowDeleteMessagePopUp(false)}
      >
        <DialogTitle
          // sx={{ background: "#3D7CC9", p: "1rem 0", position: "relative" }}
          sx={{ position: "relative" }}
        >
          {/* <Typography
            variant="h5"
            component="div"
            align="center"
            color="#fff"
            fontWeight="bold"
          >
            Remove User
          </Typography> */}

          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setShowDeleteMessagePopUp(false)}
            sx={{
              position: "absolute",
              top: "50%",
              right: "1rem",
              transform: "translate(0, -50%)",
              color: "#3D7CC9",
              background: "#fff",
              width: "1.5rem",
              height: "1.5rem",
              ":hover": {
                color: "#3D7CC9",
                background: "#fff",
                filter: "bightness(1.2)",
              },
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Container
            sx={{
              m: "0 !important",
              p: "0 !important",
              display: "grid",
              gap: ".7rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InfoOutlined
              color="warning"
              sx={{
                fontSize: "clamp(30px, 10vw, 55px)",
                mx: "auto",
                display: "block",
              }}
            />

            <Typography
              variant="h3"
              color="black"
              align="center"
              sx={{
                fontSize: "clamp(20px, 5vw, 25px)",
                fontWeight: "500",
              }}
            >
              Are you sure
            </Typography>

            <Typography
              component="p"
              color="#7A7A7A"
              align="center"
              sx={
                {
                  // fontSize: "clamp(20px, 5vw, 25px)",
                }
              }
            >
              You want to delete this message
            </Typography>

            <div className="btns-group">
              <div className="btn-item">
                <Button
                  fullWidth
                  elevation={2}
                  variant="contained"
                  color="primary"
                  onClick={() => deleteMessage()}
                  sx={{ textTransform: "capitalize" }}
                >
                  Yes
                </Button>
              </div>
              <div className="btn-item">
                <Button
                  fullWidth
                  elevation={2}
                  variant="outlined"
                  color="warning"
                  onClick={() => setShowDeleteMessagePopUp(false)}
                  sx={{ textTransform: "capitalize" }}
                >
                  No
                </Button>
              </div>
            </div>
          </Container>
        </DialogContent>
      </Dialog>

      {/* send to insurance popup */}
      <Dialog
        open={showInsurancePopup}
        onClose={() => setShowInsurancePopup(false)}
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{
          sx: {
            overflow: "visible",
            background: '#fff',
            width: ['82vw', '82vw', '90vw'],
            maxWidth: ['300px'],
            height: ['fit-content'],
            margin: "auto",
            borderRadius: ['8px', '8px', '8px'],

          },
        }}
      >
        <DialogTitle
          sx={{
            position: "relative",
            color: "white",
            display: "flex",
            justifyContent: "center",
            fontWeight: "600",
            background: "#3D7CC9",
            padding: "1rem",
            borderRadius: "8px 8px 8px 8px",
            placeContent: "center",
          }}
        >
          Send to Ins Dashboard
        </DialogTitle>

        <DialogContent>
          <div style={{ margin: "auto", }} className="insPopUp">
            <div className="insBody" >
              <div>
                <InputLabel id="demo-simple-select-helper-label">
                  Patient Name
                </InputLabel>
                <Select
                  defaultValue={FirstName}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  disabled={true}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={FirstName}>
                    <em>{FirstName}</em>
                  </MenuItem>
                </Select>
              </div>
              <div style={{ marginTop: "10px" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select Document
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  // value={age}
                  sx={{ width: "100%" }}
                  onChange={(e) => setInsFile(e.target.value)}
                >
                  {data?.map((listItem, i) => {
                    return (
                      listItem?.sendToInsDash && (
                        <MenuItem key={i} value={listItem}> {listItem.title}</MenuItem>
                      )
                    );
                  })}
                </Select>
              </div>
            </div>

            <center>
              <Button
                style={{
                  marginTop: "20px",
                  width: "80%",
                  borderRadius: "20px",
                }}
                variant="contained"
                onClick={() => sendToInsurance({insFile})}
              >Send</Button>
            </center>
          </div>
        </DialogContent>

        <IconButton
          aria-label="close"
          onClick={() => setShowInsurancePopup(false)}
          sx={{
            position: "absolute",
            right: "30px",
            top: 0,
            zIndex: "1",
            transform: "translate(50%, -50%)",
            color: "#FFFFFF",
            backgroundColor: "#fff",
            width: "30px",
            height: "30px",
            cursor: "pointer",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#fff",
            },
            display: ['flex']
          }}
        >
          <img alt="" src={crossIconOrange}></img>
        </IconButton>
      </Dialog>
      {/* uploaded file box popup */}

      <DocumentPopover ref={documentPopoverRef} />

      <Dialog
        // fullScreen
        PaperProps={{
          style: {
            overflow: "visible",
          },
        }}
        sx={{
          m: "auto",
          // width: "90vw",
          // height: "90vh",
        }}
        // open={showUploadedFile}
        onClose={() => {
          setShowUploadedFile(false);
          setUploadedDocs([]);
        }}
      >
        <Container style={{ background: "#e6e6e6", padding: "10px" }}>
          <Card style={{ background: "#e6e6e6" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#e6e6e6",
                height: "70vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {imgSrc && (
                <CardMedia
                  component="img"
                  image={imgSrc}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="green iguana"
                />
              )}
              {videoSrc && (
                <CardMedia
                  component="video"
                  image={videoSrc}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="video 1"
                />
              )}
              {pdfSrc && (
                <iframe
                  src={pdfSrc}
                  title={`pdfSrc-4`}
                  style={{ height: "650px", width: "600px" }}
                />
              )}
            </div>
            {/* container to show the small doc plates */}
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "10px",
              }}
            >
              {uploadedDocs?.map((item, id) => {
                return (
                  <>
                    {docType === "image" && (
                      <div style={{ position: "relative" }}>
                        <ImageListItem
                          onClick={() => {
                            setImgSrc(mediaUrlsList[id]);
                            setSelectedDocIdx(id);
                          }}
                          key={item.lastModified}
                          // className="selectedDocIdx==idx?'activeDoc':''"
                          style={{
                            border:
                              selectedDocIdx == id ? "3px solid #00bfa5" : "",
                            margin: "5px",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src={mediaUrlsList[id]}
                            srcSet={mediaUrlsList[id]}
                            style={{
                              width: "100px",
                              height: "100px",
                              padding: "5px",
                            }}
                            alt={item.name}
                            loading="lazy"
                          />
                        </ImageListItem>
                        <IconButton
                          aria-label="close"
                          onClick={() => removeDoc(id)}
                          sx={{
                            position: "absolute",
                            right: 5,
                            top: 5,
                            color: "#FFFFFF",
                            backgroundColor: "#FF8300",
                            width: "24px",
                            height: "24px",
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: "#ff8300",
                            },
                          }}
                        >
                          <Close sx={{ fontSize: 20 }} />
                        </IconButton>
                      </div>
                    )}
                    {docType === "video" && (
                      <div style={{ position: "relative" }}>
                        <ImageListItem
                          onClick={() => {
                            setVideoSrc(mediaUrlsList[id]);
                            setSelectedDocIdx(id);
                          }}
                          key={item.lastModified}
                          // className="selectedDocIdx==idx?'activeDoc':''"
                          style={{
                            border:
                              selectedDocIdx == id ? "3px solid #00bfa5" : "",
                            margin: "5px",
                            borderRadius: "10px",
                          }}
                        >
                          <video
                            src={mediaUrlsList[id]}
                            srcSet={mediaUrlsList[id]}
                            style={{
                              width: "100px",
                              height: "100px",
                              padding: "5px",
                            }}
                            alt={item.name}
                            loading="lazy"
                          />
                        </ImageListItem>
                        <IconButton
                          aria-label="close"
                          onClick={() => removeDoc(id)}
                          sx={{
                            position: "absolute",
                            right: 5,
                            top: 5,
                            color: "#FFFFFF",
                            backgroundColor: "#FF8300",
                            width: "24px",
                            height: "24px",
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: "#ff8300",
                            },
                          }}
                        >
                          <Close sx={{ fontSize: 20 }} />
                        </IconButton>
                      </div>
                    )}
                    {docType === "pdf" && (
                      <div style={{ position: "relative" }}>
                        <ImageListItem
                          key={item.lastModified}
                          // style={{
                          //   width: "50px",
                          //   height: "100px",
                          //   marginLeft: "10px",
                          // }}
                          style={{
                            border:
                              selectedDocIdx == id ? "2px solid #00bfa5" : "",
                            margin: "5px",
                            borderRadius: "10px",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                position: "absolute",
                                height: "100px",
                                width: "100px",
                              }}
                              onClick={() => {
                                setPdfSrc(mediaUrlsList[id]);
                                setSelectedDocIdx(id);
                              }}
                              className="overlay"
                            ></div>
                            <iframe
                              style={{
                                width: "100px",
                                height: "100px",
                              }}
                              src={mediaUrlsList[id]}
                              key={item.lastModified}
                              title={item.lastModified}
                            />
                          </div>
                        </ImageListItem>
                        <IconButton
                          aria-label="close"
                          onClick={() => removeDoc(id)}
                          sx={{
                            position: "absolute",
                            right: 5,
                            top: 5,
                            color: "#FFFFFF",
                            backgroundColor: "#FF8300",
                            width: "24px",
                            height: "24px",
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: "#ff8300",
                            },
                          }}
                        >
                          <Close sx={{ fontSize: 20 }} />
                        </IconButton>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <CardActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#e6e6e6",
              }}
            >
              <Box sx={{ minWidth: 400 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Doc Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={fileName}
                    style={{ background: "white" }}
                    label="Age"
                    onChange={(e) => setFileName(e.target.value)}
                  >
                    {data?.map((item) => {
                      return (
                        <MenuItem
                          key={item.Key}
                          onClick={() => {
                            updateSelectedFileProps(item, selectedDocIdx);
                          }}
                          value={item.Key}
                        >
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Button
                style={{
                  fontSize: "15px",
                  marginRight: "17px",
                }}
                variant="contained"
                onClick={() => sendFile()}
                size="small"
                color="primary"
                endIcon={<SendIcon />}
              >
                Send
              </Button>
            </CardActions>
          </Card>
          <IconButton
            aria-label="close"
            onClick={() => setShowUploadedFile(false)}
            sx={{
              position: "absolute",
              right: 5,
              top: 5,
              color: "#FFFFFF",
              backgroundColor: "#FF8300",
              width: "24px",
              height: "24px",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#ff8300",
              },
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
        </Container>
      </Dialog>

      {/* new upload doc modal */}
      <Dialog
        fullScreen
        open={showUploadedFile}
        // sx={{ width: "90vw", maxWidth: "90vw" }}
        PaperProps={{
          style: {
            overflow: "visible",
          },
        }}
        sx={{
          m: "auto",
          width: "90vw",
          height: "90vh",
        }}
        onClose={() => {
          handleClose();
          setShowUploadedFile(false);
          setUploadedDocs([]);
        }}
      >
        <div className="uploadContainer">
          <div className="uploadHeading">Upload Documents</div>
          <div className="uploadNewDoc">
            <FormControl
              placeholder="  Select Type Of Document"
              sx={{
                minWidth: "18rem",
              }}
            >
              {!showGeneralChatMessages && (
                <>
                  <InputLabel id="demo-simple-select-label">
                    Select Type Of Document
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={fileName}
                    style={{ background: "white" }}
                    label="Select Type Of Document"
                    onChange={(e) => setFileName(e.target.value)}
                  >
                    {uploadDocumentTags?.map((item) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            updateSelectedFileProps(item, selectedDocIdx);
                          }}
                          value={item?.Key}
                          key={item?.Key}
                        >
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </>
              )}
            </FormControl>
          </div>
          <div className="uploadBody">
            <div className="uploadPreview">
              <div className="docPreview">
                {imgSrc && (
                  <CardMedia
                    component="img"
                    image={imgSrc}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    alt="green iguana"
                  />
                )}
                {videoSrc && (
                  <CardMedia
                    component="video"
                    image={videoSrc}
                    // controls
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    alt="Video 2"
                  />
                )}
                {pdfSrc && (
                  <iframe
                    src={pdfSrc}
                    title={`pdfSrc-3`}
                    style={{
                      height: "100%",
                      width: "100%",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                )}
                {docSrc && (
                  <>
                    {docType === "doc" || docType === "excel" ? (
                      <iframe
                        src={docSrc}
                        style={{ height: "650px", width: "600px" }}
                        title={`doc-excel`}
                      />
                    ) : (
                      <div>
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          className="overlay"
                          data-type={docType}
                        >
                          No Preview Available
                        </div>
                        <iframe
                          // src={docSrc}
                          src=""
                          title="empty"
                          style={{ visibility: "hidden" }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="uploadRightContainer">
              <div className="uploadedThumbnails">
                <div
                  style={
                    {
                      // height: "100%",
                      // width: "100%",
                      // marginTop: "20px",
                      // marginBottom: "15px",
                    }
                  }
                  className="docThumbnail"
                  onClick={() => handleUploadAnotherDocument()}
                >
                  <div>
                    <CloudUploadIcon
                      style={{ color: "blue", textAlign: "center" }}
                    ></CloudUploadIcon>
                  </div>
                  <div style={{ color: "blue", textAlign: "center" }}>
                    Upload any other document
                  </div>
                </div>
                {uploadedDocs?.map((item, id) => {
                  return (
                    <>
                      {docType === "image" && (
                        <>
                          <div className="completeDocThumbnail" key={id}>
                            <div
                              className="docThumbnail"
                              onClick={() => {
                                setImgSrc(mediaUrlsList[id]);
                                setSelectedDocIdx(id);
                              }}
                              style={{
                                position: "relative",
                                border:
                                  selectedDocIdx == id
                                    ? "3px solid #00bfa5"
                                    : "",
                                // margin: "5px",
                                // maxHeight: "100%",
                                borderRadius: "10px",
                                maxWidth: "100%",
                              }}
                            >
                              <ImageListItem
                                key={item.lastModified}
                              // className="selectedDocIdx==idx?'activeDoc':''"
                              >
                                <img
                                  src={mediaUrlsList[id]}
                                  srcSet={mediaUrlsList[id]}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    padding: "5px",
                                  }}
                                  alt={item.name}
                                  loading="lazy"
                                />
                              </ImageListItem>
                              <IconButton
                                aria-label="close"
                                onClick={() => removeDoc(id)}
                                sx={{
                                  position: "absolute",
                                  right: 0,
                                  top: 0,
                                  transform: "translate(50%, -50%)",
                                  color: "#FFFFFF",
                                  width: "24px",
                                  height: "24px",
                                  backgroundColor: "#fff",
                                  borderRadius: "100%",
                                  "&.MuiButtonBase-root:hover": {
                                    bgcolor: "#fff",
                                  },
                                }}
                              >
                                <Cancel sx={{ color: "blue", p: "0", }} />
                              </IconButton>
                            </div>

                            <div className="docTitle"> {item?.name}</div>
                          </div>
                        </>
                      )}
                      {docType === "video" && (
                        <>
                          <div className="completeDocThumbnail" key={id}>
                            <div
                              className="docThumbnail"
                              onClick={() => {
                                setVideoSrc(mediaUrlsList[id]);
                                setSelectedDocIdx(id);
                              }}
                              style={{
                                position: "relative",
                                border:
                                  selectedDocIdx == id
                                    ? "3px solid #00bfa5"
                                    : "",
                                // margin: "5px",
                                // maxHeight: "100%",
                                borderRadius: "10px",
                                maxWidth: "100%",
                              }}
                            >
                              <ImageListItem
                                key={item.lastModified}
                              // className="selectedDocIdx==idx?'activeDoc':''"
                              >
                                <video
                                  src={mediaUrlsList[id]}
                                  srcSet={mediaUrlsList[id]}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    padding: "5px",
                                  }}
                                  alt={item.name}
                                  loading="lazy"
                                />
                              </ImageListItem>
                              <IconButton
                                aria-label="close"
                                onClick={() => removeDoc(id)}
                                sx={{
                                  position: "absolute",
                                  right: 0,
                                  top: 0,
                                  transform: "translate(50%, -50%)",
                                  color: "#FFFFFF",
                                  width: "24px",
                                  height: "24px",
                                  backgroundColor: "#fff",
                                  borderRadius: "100%",
                                  "&.MuiButtonBase-root:hover": {
                                    bgcolor: "#fff",
                                  },
                                }}
                              >
                                <Cancel sx={{ color: "blue", p: "0", }} />
                              </IconButton>
                            </div>

                            <div className="docTitle"> {item?.name}</div>
                          </div>
                        </>
                      )}
                      {docType === "pdf" && (
                        <div className="completeDocThumbnail">
                          <div
                            className="docThumbnail"
                            onClick={() => {
                              setPdfSrc(mediaUrlsList[id]);
                              setSelectedDocIdx(id);
                            }}
                            style={{
                              position: "relative",
                              border:
                                selectedDocIdx == id ? "3px solid #00bfa5" : "",
                              // margin: "5px",
                              // maxHeight: "100%",
                              borderRadius: "10px",
                              maxWidth: "100%",
                            }}
                          >
                            <ImageListItem
                              key={item.lastModified}
                              style={{
                                width: "50px",
                                height: "100px",
                                marginLeft: "10px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    height: "100px",
                                    width: "100px",
                                  }}
                                  className="overlay"
                                ></div>
                                <iframe
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  src={mediaUrlsList[id]}
                                  key={item.lastModified}
                                  title={item.lastModified}
                                />
                              </div>
                            </ImageListItem>
                            <IconButton
                              aria-label="close"
                              onClick={() => removeDoc(id)}
                              sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                                transform: "translate(50%, -50%)",
                                color: "#FFFFFF",
                                backgroundColor: "blue",
                                width: "24px",
                                height: "24px",
                                "&.MuiButtonBase-root:hover": {
                                  bgcolor: "#ff8300",
                                },
                              }}
                            >
                              <Close sx={{ fontSize: 20 }} />
                            </IconButton>
                          </div>
                          <div className="docTitle"> {item?.name}</div>
                        </div>
                      )}
                      {docType === "csv" && (
                        <div className="completeDocThumbnail">
                          <div
                            className="docThumbnail"
                            onClick={() => {
                              setDocSrc(mediaUrlsList[id]);
                              setSelectedDocIdx(id);
                            }}
                            style={{
                              position: "relative",
                              border:
                                selectedDocIdx == id ? "3px solid #00bfa5" : "",
                              margin: "5px",
                              borderRadius: "10px",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          >
                            <ImageListItem
                              key={item.lastModified}
                              style={{
                                width: "50px",
                                height: "100px",
                                marginLeft: "10px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    height: "100px",
                                    width: "100px",
                                  }}
                                  className="overlay"
                                ></div>
                                <ArticleIcon
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    marginLeft: "-2rem",
                                  }}
                                ></ArticleIcon>
                              </div>
                            </ImageListItem>
                            <IconButton
                              aria-label="close"
                              onClick={() => removeDoc(id)}
                              sx={{
                                position: "absolute",
                                right: 5,
                                top: 5,
                                color: "#FFFFFF",
                                backgroundColor: "blue",
                                width: "24px",
                                height: "24px",
                                "&.MuiButtonBase-root:hover": {
                                  bgcolor: "#ff8300",
                                },
                              }}
                            >
                              <Close sx={{ fontSize: 20 }} />
                            </IconButton>
                          </div>
                          <div className="docTitle"> {item?.name}</div>
                        </div>
                      )}
                      {(docType === "doc" || docType === "excel") && (
                        <div className="completeDocThumbnail">
                          <div
                            className="docThumbnail"
                            onClick={() => {
                              setDocSrc(mediaUrlsList[id]);
                              setSelectedDocIdx(id);
                            }}
                            style={{
                              position: "relative",
                              border:
                                selectedDocIdx == id ? "3px solid #00bfa5" : "",
                              margin: "5px",
                              borderRadius: "10px",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          >
                            <ImageListItem
                              key={item.lastModified}
                              style={{
                                width: "50px",
                                height: "100px",
                                marginLeft: "10px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    height: "100px",
                                    width: "100px",
                                  }}
                                  className="overlay"
                                ></div>
                                <iframe
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  src={mediaUrlsList[id]}
                                  key={item.lastModified}
                                  title={item.lastModified}
                                />
                              </div>
                            </ImageListItem>
                            <IconButton
                              aria-label="close"
                              onClick={() => removeDoc(id)}
                              sx={{
                                position: "absolute",
                                right: 5,
                                top: 5,
                                color: "#FFFFFF",
                                backgroundColor: "blue",
                                width: "24px",
                                height: "24px",
                                "&.MuiButtonBase-root:hover": {
                                  bgcolor: "#ff8300",
                                },
                              }}
                            >
                              <Close sx={{ fontSize: 20 }} />
                            </IconButton>
                          </div>
                          <div className="docTitle"> {item?.name}</div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div className="sendUploadedDocs">
                <Button
                  onClick={() => {
                    sendFile();
                    handleClose();
                  }}
                >
                  Send Document
                  <ArrowForwardIcon></ArrowForwardIcon>
                </Button>
              </div>
            </div>
          </div>

          <IconButton
            aria-label="close"
            onClick={() => {
              setShowUploadedFile(false);
              handleClose();
            }}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              transform: "translate(50%, -50%)",
              color: "#FFFFFF",
              width: "24px",
              height: "24px",
              backgroundColor: "#fff",
              borderRadius: "100%",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#fff",
              },
            }}
          >
            <Cancel
              sx={{
                color: "#FF8300",
                p: "0",
              }}
            />
          </IconButton>
        </div>
      </Dialog>

      {/* send message template */}
      <Dialog
        open={showMessageTemplatePopUp}
        onClose={() => setShowMessageTemplatePopUp(false)}
      >
        <DialogTitle
          sx={{ position: "relative" }}
        >

          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setShowMessageTemplatePopUp(false)}
            sx={{
              position: "absolute",
              top: "50%",
              right: "1rem",
              transform: "translate(0, -50%)",
              color: "#3D7CC9",
              background: "#fff",
              width: "1.5rem",
              height: "1.5rem",
              ":hover": {
                color: "#3D7CC9",
                background: "#fff",
                filter: "bightness(1.2)",
              },
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Container
            sx={{
              m: "0 !important",
              p: "0 !important",
              display: "grid",
              gap: ".7rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InfoOutlined
              color="warning"
              sx={{
                fontSize: "clamp(30px, 10vw, 55px)",
                mx: "auto",
                display: "block",
              }}
            />

            <Typography
              variant="h3"
              color="black"
              align="center"
              sx={{
                fontSize: "clamp(20px, 5vw, 25px)",
                fontWeight: "500",
              }}
            >
              Are you sure
            </Typography>

            <Typography
              component="p"
              color="#7A7A7A"
              align="center"
            >
              You want to send this template
            </Typography>

            <div className="btns-group">
              <div className="btn-item">
                <Button
                  fullWidth
                  elevation={2}
                  variant="contained"
                  color="primary"
                  onClick={() => sendChatTemplate()}
                  sx={{ textTransform: "capitalize" }}
                >
                  Yes
                </Button>
              </div>
              <div className="btn-item">
                <Button
                  fullWidth
                  elevation={2}
                  variant="outlined"
                  color="warning"
                  onClick={() => setShowMessageTemplatePopUp(false)}
                  sx={{ textTransform: "capitalize" }}
                >
                  No
                </Button>
              </div>
            </div>
          </Container>
        </DialogContent>
      </Dialog>

      {/* chat media dialog  */}
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        PaperProps={{
          style: {
            overflow: "visible",
          },
        }}
        sx={{
          m: "auto",
        }}
        onClose={() => setShowChatMediaDialog(false)}
      >
        <Container style={{ background: "#e6e6e6", padding: "10px" }}>
          <Card style={{ background: "#e6e6e6" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#e6e6e6",
                height: "70vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {imgSrc && (
                <CardMedia
                  component="img"
                  image={imgSrc}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="green iguana"
                />
              )}
              {videoSrc && (
                <CardMedia
                  component="video"
                  image={videoSrc}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="video 3"
                />
              )}
              {pdfSrc && (
                <iframe
                  src={pdfSrc}
                  title={`pdfSrc-2`}
                  style={{ height: "650px", width: "600px" }}
                />
              )}
            </div>
            {/* container to show the small doc plates */}
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "10px",
              }}
            >
              {mediaUrlsList?.map((item, id) => {
                return (
                  <>
                    {docType === "image" && (
                      <div style={{ position: "relative" }} key={id}>
                        <ImageListItem
                          onClick={() => {
                            setImgSrc(mediaUrlsList[id]);
                            setSelectedDocIdx(id);
                          }}
                          key={item.lastModified}
                          // className="selectedDocIdx==idx?'activeDoc':''"
                          style={{
                            border:
                              selectedDocIdx == id ? "3px solid #00bfa5" : "",
                            margin: "5px",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src={mediaUrlsList[id]}
                            srcSet={mediaUrlsList[id]}
                            style={{
                              width: "100px",
                              height: "100px",
                              padding: "5px",
                            }}
                            alt={item.name}
                            loading="lazy"
                          />
                        </ImageListItem>
                      </div>
                    )}
                    {docType === "video" && (
                      <div style={{ position: "relative" }} key={id}>
                        <ImageListItem
                          onClick={() => {
                            setVideoSrc(mediaUrlsList[id]);
                            setSelectedDocIdx(id);
                          }}
                          key={item.lastModified}
                          // className="selectedDocIdx==idx?'activeDoc':''"
                          style={{
                            border:
                              selectedDocIdx == id ? "3px solid #00bfa5" : "",
                            margin: "5px",
                            borderRadius: "10px",
                          }}
                        >
                          <video
                            src={mediaUrlsList[id]}
                            srcSet={mediaUrlsList[id]}
                            style={{
                              width: "100px",
                              height: "100px",
                              padding: "5px",
                            }}
                            alt={item.name}
                            loading="lazy"
                          />
                        </ImageListItem>
                      </div>
                    )}
                    {docType === "pdf" && (
                      <div style={{ position: "relative" }}>
                        <ImageListItem
                          key={item.lastModified}
                          style={{
                            border:
                              selectedDocIdx == id ? "2px solid #00bfa5" : "",
                            margin: "5px",
                            borderRadius: "10px",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                position: "absolute",
                                height: "100px",
                                width: "100px",
                              }}
                              onClick={() => {
                                setPdfSrc(mediaUrlsList[id]);
                                setSelectedDocIdx(id);
                              }}
                              className="overlay"
                            ></div>
                            <iframe
                              style={{
                                width: "100px",
                                height: "100px",
                              }}
                              src={mediaUrlsList[id]}
                              key={item.lastModified}
                              title={item.lastModified}
                            />
                          </div>
                        </ImageListItem>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </Card>
          <IconButton
            aria-label="close"
            onClick={() => setShowChatMediaDialog(false)}
            sx={{
              position: "absolute",
              right: 5,
              top: 5,
              color: "#FFFFFF",
              backgroundColor: "#fff",
              width: "24px",
              height: "24px",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#fff",
              },
            }}
          >
            <Cancel
              sx={{
                color: "#FF8300",
                p: "0",
              }}
            />
          </IconButton>
        </Container>
      </Dialog>

      {/* new chat media dialog */}
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        PaperProps={{
          sx: {
            overflow: "visible",
            width: ['80vw', '80vw', '90vw'],
            height: ['74vh', '74vh', '90vh'],
            marginTop: ['13vh', '13vh', 'unset'],
            borderRadius: '8px'
          },
        }}
        // sx={{
        //   m: "auto",
        //   width: "90vw",
        //   height: "90vh",
        // }}
        open={showChatMediaDialog}
        // sx={{ width: "90vw", maxWidth: "90vw" }}
        onClose={() => setShowChatMediaDialog(false)}
      >
        <Box className="uploadContainer" sx={{ display: ['none', 'none', 'flex'] }}>
          <div className="uploadHeading">
            {selectedMessage?.MessageBody}
            <IconButton
              aria-label="close"
              onClick={() => setShowChatMediaDialog(false)}
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                transform: "translate(50%, -50%)",
                color: "#FFFFFF",
                backgroundColor: "#fff",
                width: "24px",
                height: "24px",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#fff",
                },
              }}
            >
              <Cancel
                sx={{
                  color: "#FF8300",
                  p: "0",
                }}
              />{" "}
            </IconButton>
          </div>

          <div className="uploadBody" sx={{ position: "relative" }}>
            <Box
              className="uploadPreview"
              sx={{ pt: { md: "1rem", xl: "2rem" } }}
            >
              {pdfSrc && (
                <Box
                  className="printBox grab-attention-element"
                  sx={{
                    mb: { md: "1rem", xl: "2rem" },
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <svg
                    style={{
                      position: "absolute",
                      right: "4rem",
                      bottom: "-38px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="60.447"
                    height="61.904"
                    viewBox="0 0 60.447 61.904"
                  >
                    <path
                      id="_216437_back_arrow_icon"
                      data-name="216437_back_arrow_icon"
                      d="M27.692,16.04V8.462a2.963,2.963,0,0,1,5.055-2.1L51.385,24.75,32.748,43.132a2.962,2.962,0,0,1-5.055-2.094V33.667c-8.144.2-17.044,1.676-23.692,11.814V42.519A26.646,26.646,0,0,1,27.692,16.04Z"
                      transform="matrix(0.602, 0.799, -0.799, 0.602, 33.915, -6.505)"
                      fill="#3D7CC9"
                    />
                  </svg>
                  Click the button below to print the document
                </Box>
              )}
              <div
                className="docPreview"
                style={{
                  position: "relative",
                  marginTop: docSrc ? "-2rem" : "",
                }}
              >
                {imgSrc && (
                  <CardMedia
                    id="downloadableImage"
                    component="img"
                    image={imgSrc}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    alt="green iguana"
                  />
                )}
                {videoSrc && (
                  <CardMedia
                    id="downloadableVideo"
                    component="video"
                    src={videoSrc}
                    controls
                    style={{ maxWidth: "100%", maxHeight: "100%", backgroundColor: 'black' }}
                    data-info="Video Preview"
                  />
                )}
                {pdfSrc && (
                  <iframe
                    id="pdfIframe"
                    src={pdfSrc}
                    style={{ height: "650px", width: "600px" }}
                    title={`pdf-1`}
                  />
                )}
                {docSrc && (
                  <>
                    {docType === "doc" ? (
                      <iframe
                        id="docIframe"
                        src={docSrc}
                        style={{ height: "650px", width: "600px" }}
                        title={`doc-2`}
                      />
                    ) : (
                      <div>
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          className="overlay"
                          data-type={docType}
                        >
                          No Preview Available
                        </div>
                        <iframe
                          // src={docSrc}
                          style={{ visibility: "hidden" }}
                          title="doc-1"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              {docSrc && (
                <Box
                  className="printBox grab-attention-element"
                  sx={{ my: { md: "1.2rem", xl: "2rem" } }}
                >
                  <svg
                    style={{
                      position: "absolute",
                      right: "3rem",
                      bottom: "-38px",
                      top: "-38px",
                      transform: "rotateX(180deg)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="60.447"
                    height="61.904"
                    viewBox="0 0 60.447 61.904"
                  >
                    <path
                      id="_216437_back_arrow_icon"
                      data-name="216437_back_arrow_icon"
                      d="M27.692,16.04V8.462a2.963,2.963,0,0,1,5.055-2.1L51.385,24.75,32.748,43.132a2.962,2.962,0,0,1-5.055-2.094V33.667c-8.144.2-17.044,1.676-23.692,11.814V42.519A26.646,26.646,0,0,1,27.692,16.04Z"
                      transform="matrix(0.602, 0.799, -0.799, 0.602, 33.915, -6.505)"
                      fill="#3D7CC9"
                    />
                  </svg>
                  Click the above button to print the document
                </Box>
              )}

            </Box>
            <Box className="uploadRightContainer" sx={{ pt: "5rem" }}>
              <div className="uploadedThumbnails">
                {mediaUrlsList?.map((item, id) => {
                  return (
                    <>
                      {docType === "image" && (
                        <>
                          <div className="completeDocThumbnail" key={id}>
                            <div
                              className="docThumbnail"
                              onClick={() => {
                                setImgSrc(mediaUrlsList[id]);
                                setSelectedDocIdx(id);
                              }}
                              style={{
                                position: "relative",
                                border:
                                  selectedDocIdx == id
                                    ? "3px solid #00bfa5"
                                    : "",
                                margin: "5px",
                                borderRadius: "10px",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            >
                              <ImageListItem
                                key={item.lastModified}
                              // className="selectedDocIdx==idx?'activeDoc':''"
                              >
                                <img
                                  src={mediaUrlsList[id]}
                                  srcSet={mediaUrlsList[id]}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    padding: "5px",
                                  }}
                                  alt={item.name}
                                  loading="lazy"
                                />
                              </ImageListItem>
                            </div>
                          </div>
                        </>
                      )}
                      {docType === "video" && (
                        <>
                          <div className="completeDocThumbnail" key={id}>
                            <div
                              className="docThumbnail"
                              onClick={() => {
                                setVideoSrc(mediaUrlsList[id]);
                                setSelectedDocIdx(id);
                              }}
                              style={{
                                position: "relative",
                                border:
                                  selectedDocIdx == id
                                    ? "3px solid #00bfa5"
                                    : "",
                                margin: "5px",
                                borderRadius: "10px",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            >
                              <ImageListItem
                                key={item.lastModified}
                              // className="selectedDocIdx==idx?'activeDoc':''"
                              >
                                <Box
                                  component={'video'}
                                  src={mediaUrlsList[id]}
                                  srcSet={mediaUrlsList[id]}
                                  style={{
                                    width: "150px",
                                    padding: "5px",
                                    backgroundColor: '#000',
                                    borderRadius: '6px'
                                  }}
                                  data-info={`video preview icon`}
                                  loading="lazy"
                                />
                              </ImageListItem>
                            </div>
                          </div>
                        </>
                      )}
                      {docType === "pdf" && (
                        <div className="completeDocThumbnail">
                          <div
                            className="docThumbnail"
                            onClick={() => {
                              setPdfSrc(mediaUrlsList[id]);
                              setSelectedDocIdx(id);
                            }}
                            style={{
                              position: "relative",
                              border:
                                selectedDocIdx == id ? "3px solid #00bfa5" : "",
                              margin: "5px",
                              borderRadius: "10px",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          >
                            <ImageListItem
                              key={item.lastModified}
                              style={{
                                width: "50px",
                                height: "100px",
                                marginLeft: "10px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  marginLeft: "-1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    height: "100px",
                                    width: "100px",
                                  }}
                                  className="overlay"
                                ></div>
                                <iframe
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  src={mediaUrlsList[id]}
                                  key={item.lastModified}
                                  title={item.lastModified}
                                />
                              </div>
                            </ImageListItem>
                          </div>
                          <div className="docTitle"> {item?.name}</div>
                        </div>
                      )}
                      {docType === "doc" && (
                        <div className="completeDocThumbnail">
                          <div
                            className="docThumbnail"
                            onClick={() => {
                              setDocSrc(mediaUrlsList[id]);
                              setSelectedDocIdx(id);
                            }}
                            style={{
                              position: "relative",
                              border:
                                selectedDocIdx == id ? "3px solid #00bfa5" : "",
                              margin: "5px",
                              borderRadius: "10px",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          >
                            <ImageListItem
                              key={item.lastModified}
                              style={{
                                width: "50px",
                                height: "100px",
                                marginLeft: "10px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  marginLeft: "-1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    height: "100px",
                                    width: "100px",
                                  }}
                                  className="overlay"
                                ></div>
                                <iframe
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  src={mediaUrlsList[id]}
                                  key={item.lastModified}
                                  title={item.lastModified}
                                />
                              </div>
                            </ImageListItem>
                          </div>
                          <div className="docTitle"> {item?.name}</div>
                        </div>
                      )}
                      {docType === "csv" && (
                        <div className="completeDocThumbnail">
                          <div
                            className="docThumbnail"
                            onClick={() => {
                              setDocSrc(mediaUrlsList[id]);
                              setSelectedDocIdx(id);
                            }}
                            style={{
                              position: "relative",
                              border: selectedDocIdx == id ? "3px solid #00bfa5" : "",
                              margin: "5px",
                              borderRadius: "10px",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          >
                            <ImageListItem
                              key={item.lastModified}
                              style={{
                                width: "50px",
                                height: "100px",
                                marginLeft: "10px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  marginLeft: "-1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    height: "100px",
                                    width: "100px",
                                  }}
                                  className="overlay"
                                ></div>
                                <ArticleIcon
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    marginLeft: "-2rem",
                                  }}
                                ></ArticleIcon>
                              </div>
                            </ImageListItem>
                          </div>
                          <div className="docTitle"> {item?.name}</div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>

              {docType === "image" && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    textAlign: "right",
                    display: "flex",
                    p: "1rem 0",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography sx={{ color: "#7A7A7A" }}>
                    *Only selected document will get printed
                  </Typography>
                  <Button
                    sx={{
                      background: "#FFBE00",
                      color: "#fff",
                      textTransform: "revert",
                      borderRadius: "100px",
                      p: ".25rem 5rem",
                      fontSize: "18px",
                      fontWeight: "600",
                      ":hover": { background: "#FFBE00", color: "#fff" },
                    }}
                    variant="contained"
                    onClick={() => printDocument()}
                    disableElevation
                    startIcon={<Print />}
                  >
                    Print
                  </Button>
                </Box>
              )}
            </Box>
          </div>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setShowChatMediaDialog(false)}
          sx={{
            position: "absolute",
            right: "30px",
            top: 0,
            zIndex: "1",
            transform: "translate(50%, -50%)",
            color: "#FFFFFF",
            backgroundColor: "#fff",
            width: "30px",
            height: "30px",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#fff",
            },
            cursor: "pointer",
            display: ['flex', 'flex', 'none']
          }}
        >
          <img src={crossIconOrange} alt="close" />
        </IconButton>
        <Box sx={{ display: ['flex', 'flex', 'none'], height: ['74vh', '74vh', '85vh'], flexDirection: "column", position: 'relative', }}>
          <PreviewDocument allDocs={selectedMessage?.Attributes?.multiUrl || [selectedMessage?.Media]} documentTitle={selectedMessage?.MessageBody} previewUrls={mediaUrlsList} docType={docType} closeDialog={setShowChatMediaDialog} />
        </Box>
      </Dialog>


      <DailogHWA title={`Preview Document`} open={isShowPreviewDocument} onClose={() => setIsShowPreviewDocument(false)}>
        <PreviewDocument allDocs={previewDocumentFiles} documentTitle={previewDocumentTitle} previewUrls={previewDocumentUrls} docType={previewDocumentDocType} showCustomHeader={false} />
      </DailogHWA>
    </>
  );
}
export default ChatBox;