import React, { useEffect, useState } from 'react'
import { CentralAccordion } from '../../component/CentralAccordion/CentralAccordion'
import { useDispatch, useSelector } from 'react-redux';
import { centralCommonDataActions, centralPatientActions, centralReportingDataActions, configActions } from '../../../_actions';
import { CentralCommonHeader } from '../../component/CommonHeader/CentralCommonHeader';
import { useNavigate } from 'react-router-dom';
import { centralPatientConstants } from '../../../_constants/centralConstant/centralPatient.constant';
import { CircularProgress } from '@mui/material';
import './centralPatientDetails.css'

export const CentralPatientDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  let commonData = useSelector(state => state.centralCommonData.commonData)
  let activeHospitalId = useSelector(state=>state.centralHospitalData.activeHospitalId)

  let patientList = useSelector(state => state.centralPatients)
  const patientListData = patientList?.centralPatients?.data;
  let fetchedReportedData = useSelector(state => state.centralCommonData.commonData?.result.fetched)
  const reportingData = useSelector(state=>state.centralReportingData?.reportingData);
  console.log('reportingData....', reportingData, patientListData, patientList);
  
  let activePatientId = ''
  let activePatientName = ''
  if(patientList?.activePatientId && !window.location.search.includes("accessToken")){
    // if(reportingData.message==='Success' && reportingData?.data?.length>0){
    //   activePatientId = reportingData.data[0].appointment_id || reportingData.data[0]._id
    //   activePatientName = reportingData.data[0].patient_name
    //   activeHospitalId = reportingData.data[0].hospitalId
    // }
    // else{
      activePatientId = patientList.activePatientId
      activePatientName = patientList.activePatientName
    // }
  }
  else{
      const parts = window.location.pathname.split('/');
      const id = parts[3]; 
      activePatientId = id
      activeHospitalId = parts[2]
      if(patientListData?.length && window.location.search.includes("accessToken")){
        patientListData.forEach(element => {
          if(element.appId === activePatientId){
            activePatientName = element.patientName
          }
      });
      }
    }
  const jsonData = commonData?.result?.data
  useEffect(() => {
   dispatch(centralCommonDataActions.getCentralCommonData())
   return ()=>dispatch(centralCommonDataActions.clearCentralCommonData())

  }, []);
  
  useEffect( () => {
     dispatch(centralReportingDataActions.getCentralReportingData({"search_query":activePatientId}));
     if(window.location.search.includes("accessToken")){
       dispatch(centralPatientActions.getCentralPatientList(activeHospitalId));
     }
  }, [activePatientId && jsonData]);

  useEffect(() => {
    activePatientName !== "" && dispatch({
        type:centralPatientConstants.SAVE_ACTIVE_CENTRAL_PATIENT_DATA,
        payload:{patientId:activePatientId,patientName:activePatientName}
    })
  }, [activePatientId, activePatientName])

  const [accordionHeaders,SetAccordionHeaders] = useState([])

  useEffect(()=>{
    if(jsonData){
        let tempArr = jsonData.map((item)=> {return item.title})
        if(tempArr && tempArr.length>0){
          SetAccordionHeaders(tempArr)
        }
    }
  },[fetchedReportedData])

  const handleBackButton = () =>{
    navigate(`/central/${activeHospitalId}`)
    dispatch(centralCommonDataActions.clearCentralCommonData())
    dispatch({
      type:centralPatientConstants.SAVE_ACTIVE_CENTRAL_PATIENT_DATA,
      payload:null
  })
  }

  return (
    <>
        <CentralCommonHeader title={activePatientName} backIcon={true} backArrowFunction = {handleBackButton} avatarIcon = {true}/>
    <div className='patientDetailsMainContainer'>
    {
     fetchedReportedData? accordionHeaders && accordionHeaders.length>0 && jsonData && accordionHeaders.map((item,index)=>{
          return <CentralAccordion type={item} data={jsonData} />
      }):
      <div className='patientDetailsLoaderContainer'>
        <CircularProgress />
        </div>
    }
    </div>
    </>
  )
}
