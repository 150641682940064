import React, { useEffect, useState } from 'react'
import './centralEquipmentModal.css'
import { CentralModal } from '../CentralModal/CentralModal';
import { CentralSelect } from '../CentralSelect/CentralSelect';
import { CentralInputField } from '../CentralInputField/CentralInputField';
import { AVAILABILITY, PROVIDED_BY, UTILIZATION_STATUS } from './constant';
import { useDispatch, useSelector } from 'react-redux';
import { centralAddOrDeleteInstrumentsService } from '../../../_services';
import { getTokenFromLocal } from '../../../_helpers/handleResponseMiddleware';
import { centralEquipmentsActions } from '../../../_actions/centralActions/centralEquipments.actions';

export const CentralEquipmentModal = React.memo((props) => {
    const { open, onClose, onAddMore, onOpen, instrumentsOptions, equipmentType='' } = props
    const reportingData = useSelector(state=>state.centralReportingData);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        equipmentCategory: '',
        equipmentName: '',
        equipmentOtherCategory: '',
        equipmentOtherName: '',
        quantity: '',
        providedBy: '',
        availability: '',
        utilizationStatus: ''
      });

    const [instrumentsDetails, setInstrumentsDetails] = useState({
        equipmentCategory: [],
        equipmentName: [],
      });
    const [isOtherCategory, setIsOtherCategory] = useState(false)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

      useEffect(() => {
        if(equipmentType==='native' && instrumentsOptions.length){
            const uniqueInstrumentsOptions = [...new Map(instrumentsOptions.map(item => [item.Equipment, item])).values()];
            const category = uniqueInstrumentsOptions.map(item => ({ 'label': item.Equipment, 'value': item.Equipment }));
            category.unshift({ label: 'Others', value: 'Others' });
            setInstrumentsDetails(prev=>({...prev, equipmentCategory:category, equipmentName:[]}))
        }
        else if(equipmentType==='web' && instrumentsOptions){
            if(reportingData && reportingData?.reportingData?.data?.length){
                const disease = reportingData?.reportingData?.data[0]?.disease
                const diseaseList = Object.keys(instrumentsOptions)
                
                let diseaseObject = {}
                for(let i=0; i<diseaseList.length;i++){
                  if(diseaseList[i].toLowerCase()===disease.toLowerCase()){
                    diseaseObject = instrumentsOptions[diseaseList[i]]
                    break;
                  }
                }
                const category = Object.keys(diseaseObject).map(item=>({label:item,value:item}))
                category.unshift({ label: 'Others', value: 'Others' });
                setInstrumentsDetails(prev=>({...prev, equipmentCategory:category, equipmentName:[]}))
                
            }
        }
      }, [instrumentsOptions])
      
      const handleChange = (name, value) => {
        if(name==='equipmentCategory' && equipmentType ==='native' && instrumentsOptions.length>0){
            if(value !=='Others'){
            setIsOtherCategory(false)
            
            const uniqueInstrumentsOptions = [...new Map(instrumentsOptions.map(item => [item.Equipment, item])).values()];
            const equipmentName  = uniqueInstrumentsOptions.filter(item=>item.Equipment===value)[0]?.SubCategory
            
            if(equipmentName.length>0){
                const nameDetails = equipmentName.map(item=>({'label':item, 'value':item}))
                setInstrumentsDetails(prev=>({...prev, equipmentName:nameDetails}))
            }
        }
        else {
            setIsOtherCategory(true)
            setInstrumentsDetails(prev=>({...prev, equipmentName:[]}))
            setFormData(prev=>({...prev,
                equipmentName: '',
              }))
        }
        }
        else if(name==='equipmentCategory' && equipmentType ==='web'){
            if(value !=='Others'){
                setIsOtherCategory(false)
                if(reportingData && reportingData?.reportingData?.data?.length){
                    const disease = reportingData?.reportingData?.data[0]?.disease
                    const diseaseList = Object.keys(instrumentsOptions)
                    let diseaseObject = {}
                for(let i=0; i<diseaseList.length;i++){
                  if(diseaseList[i].toLowerCase()===disease.toLowerCase()){
                    diseaseObject = instrumentsOptions[diseaseList[i]]
                    break;
                  }
                }
                const categoryList = Object.keys(diseaseObject)
                for(let i=0; i<categoryList.length;i++){
                  if(categoryList[i].toLowerCase()===value.toLowerCase()){
                    const subCat = diseaseObject[categoryList[i]]
                    if(subCat){
                        const allSubCat = Object.keys(subCat).map(item=>({label:item, value:item}))
                        setInstrumentsDetails(prev=>({...prev, equipmentName:allSubCat}))
                    }
                    break;
                  }
                }
                }
            }
            else{
                setIsOtherCategory(true)
                setInstrumentsDetails(prev=>({...prev, equipmentName:[]}))
                setFormData(prev=>({...prev,
                equipmentName: '',
              }))
            }
        }
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const handleOnClose = () =>{
        onClose();
        setFormData(prev=>({...prev,
            equipmentCategory: '',
            equipmentName: '',
            equipmentOtherCategory: '',
            equipmentOtherName: '',
            quantity: '',
            providedBy: '',
            availability: '',
            utilizationStatus: ''
          }))
          setIsOtherCategory(false)
    }

    const handleOnSubmit = () => {
        let user = getTokenFromLocal();
    let userName = user ? user.username || user?.name : null;
    let appId = ''
    if(reportingData && reportingData.activePatientId){
            appId = reportingData.activePatientId
            const params = {"deleteInstrument":[],
                "newInstruments":[{"category": formData.equipmentCategory !=='Others'? formData.equipmentCategory : formData.equipmentOtherCategory || '',
                "equipment":formData.equipmentName || formData.equipmentOtherName,"quantity":formData.quantity,
                "providedBy" : formData.providedBy, 
                "status" : formData.availability,
                "utilisationStatus":formData.utilizationStatus,
                "createdAt":`${new Date().toISOString()}`,
                "userName":userName,
                "source":"hosChatApp"}],
                "appId":appId,"createdAt":`${new Date().toISOString()}`}
            
            centralAddOrDeleteInstrumentsService(params)
            .then(response=>{
                if(response.message ==='Success'){
                    dispatch(centralEquipmentsActions.getCentralInstrumentsList({"appId":appId}))
                }
            })
            .catch(err=>{
                console.log('Add new equipment error', err);
            })
            onAddMore();
            handleOnClose();
        }
        
      };
    
useEffect(() => {
    const categoryCondition = formData.equipmentCategory !== 'Others' ? formData.equipmentCategory : formData.equipmentOtherCategory;
    const nameCondition = instrumentsDetails.equipmentName.length > 0 ? formData.equipmentName || formData.equipmentOtherName : true;
    const quantityCondition = formData.quantity;

    if (categoryCondition && nameCondition && quantityCondition) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [formData, instrumentsDetails]);

  return (
    <CentralModal
      modalTitle="Add Equipment"
      open={open}
      onClose={handleOnClose}
      buttonTitle="Save"
      isShowDivider={(true)}
      isSubmitDisabled = {isSubmitDisabled}
      onSubmit={handleOnSubmit}
      isModalFooterShow={true}
      modalButton={
        <div className='addMoreButton' onClick={()=>onOpen()}>Add More +</div>
      }
    >
      <div className='equipmentContentContainer'>
          <CentralSelect value={formData.equipmentCategory || ''} label="Equipment Category" options={instrumentsDetails.equipmentCategory} onChange={(event) => handleChange('equipmentCategory', event.target.value)} />
        {
          !isOtherCategory? <>
            <CentralSelect value={formData.equipmentName || ''} label="Equipment Name" options={instrumentsDetails.equipmentName} onChange={(event) => handleChange('equipmentName', event.target.value)} /></>
            :
            <>
                <CentralInputField value={formData.equipmentCategory || ''} label="Other Category" handleOnChange={(event) => handleChange('equipmentOtherCategory', event.target.value)} />
                <CentralInputField value={formData.equipmentName || ''} label="Equipment Name" handleOnChange={(event) => handleChange('equipmentOtherName', event.target.value)} />
            </>
        }
        <CentralInputField value={formData.quantity || ''} label="Quantity" handleOnChange={(event) => handleChange('quantity', event.target.value)} type = {"number"}/>
        <CentralSelect value={formData.providedBy || ''} label="Provided By" options={PROVIDED_BY} onChange={(event) => handleChange('providedBy', event.target.value)}/>
        <CentralSelect value={formData.availability || ''} disabled = {!formData?.providedBy} label="Availability" options={AVAILABILITY} onChange={(event) => handleChange('availability', event.target.value)}/>
        <CentralSelect value={formData.utilizationStatus || ''} disabled = {!formData?.providedBy} label="Utilization Status" options={UTILIZATION_STATUS} onChange={(event) => handleChange('utilizationStatus', event.target.value)}/>
      </div>
    </CentralModal>
  );
}
)